* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.payment_success {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  //   background-color: #feca03;

  .info_status {
    // border: 1px solid black;
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 25%;
      margin-bottom: 1.5rem;
    }

    h1 {
      font-size: 38px;
      color: green;
    }
  }

  .navigation {
    // border: 1px solid red;
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 0;

    button {
      background-color: #feca03;
      color: #000;
      border: none;
      border-radius: 27px;
      padding: 0.5rem 2.5rem;
      transition: 0.5s ease;

      &:hover {
        color: #fff;
      }
    }
  }
}

@media (max-width: 767px) {
  .payment_success {
    .info_status {
      img {
        width: 75%;
        margin-bottom: 1.5rem;
      }
    }
  }
}
