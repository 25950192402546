.petform {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .petform_container {
    width: 100%;
    height: 100%;

    .flex1 {
      //  border: 1px solid black;
      display: flex;
      justify-content: space-between;
      gap: 15px;

      .flex1container {
        width: 47%;
        // border: 1px solid blue;
        display: flex;
        flex-direction: column;
        // gap: 5px;
        margin-top: 1rem;

        label {
          font-size: 18px;
          margin-left: 25px;
          font-family: "RecoletaSemiBold";
          margin-bottom: 5px;
        }

        input {
          width: 100%;
          border-radius: 38px;
          padding: 10px 30px 10px 30px;
          border: 0.3px solid grey;
          outline: none;

          font-family: "RecoletaRegular";
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
        }

        .datepicker {
          width: 100%;
          border-radius: 38px;
          border: 0.3px solid grey;
          outline: none;
          display: flex;
          align-items: center;
          padding-right: 15px;
          justify-content: space-between;
          font-family: "RecoletaRegular";
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

          input {
            width: 245%;
            background: transparent;
            border: none;
            outline: none;
            box-shadow: none;
            // background-color: orange;
          }
        }

        .button_filed {
          width: 100%;
          // border: 1px solid purple;
          display: flex;
          justify-content: space-between;
          gap: 20px;

          .selected-male {
            background-color: #feca03;
            color: white;

            .icon_cont {
              background-color: #feca03;
            }
          }

          .selected-female {
            background-color: #feca03;
            color: white;

            .icon_cont {
              background-color: #feca03;
            }
          }

          button {
            width: 45%;
            height: 100%;
            display: flex;
            padding: 5px 4px 5px 4px;

            border: none;
            //  justify-content: space-between;
            align-items: center;
            outline: none;
            border-radius: 38px;
            font-family: "RecoletaRegular";
            font-size: 20px;
            background-color: #dcd9d985;
            gap: 10px;

            .male_icon {
              width: 35px;
              height: 35px;
              .profile_edit_button_icon {
                width: 100%;
                height: 100%;
                background-color: #dcd9d985;
                border-radius: 50%;
                //  margin-left: 3%;
                //  font-size: 12px;
                color: white;
              }
              img {
                width: 100%;
                height: 100%;
                // border: 1px solid red;
                border-radius: 50%;
              }
            }

            .pet_profile_name {
              width: 70%;
            }
          }
        }

        .pet_go_for_walk {
          width: 100%;
          // border: 1px solid red;
          display: flex;
          justify-content: space-between;
          gap: 15px;

          .pet_walk_select {
            width: 50%;
            border-radius: 38px;
            // border: 1px solid red;
            background-color: white;
            border: 0.3px solid grey;

            select {
              width: 90%;
              padding: 0.7rem;
              outline: none;
              border: none;
              border-radius: 38px;
            }
          }
        }

        .button_field_pet {
          width: 100%;
          display: flex;
          justify-content: space-between;
          gap: 20px;

          .selected-male {
            background-color: #feca03;
            color: white;

            .icon_cont {
              background-color: #feca03;
            }
          }

          .selected-female {
            background-color: #feca03;
            color: white;

            .icon_cont {
              background-color: #feca03;
            }
          }

          button {
            width: 45%;
            height: 100%;
            display: flex;
            padding: 8px 4px 8px 4px;
            gap: 15px;
            border: none;
            // display: flex;
            justify-content: flex-start;
            align-items: center;
            outline: none;
            border-radius: 38px;
            font-family: "RecoletaRegular";
            font-size: 20px;
            background-color: #dcd9d985;

            .profile_edit_button_icon {
              color: #fff;
              font-size: 32px;
              // padding: 5px;
              border-radius: 50%;
              margin-right: 15px;
              padding: 5px;
              background-color: #dcd9d985;
              // margin-right: 15px;
              font-family: "RecoletaRegular";
            }
          }
        }

        .mealPlan {
          // border: 1px solid red;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // margin-top: 10px;

          .meap_plan_cont {
            width: 100%;
            display: flex;
            gap: 1rem;
            justify-content: flex-start;
            align-items: center;
            //  gap: 10px;
            // border: 1px solid blue;

            .veg {
              width: 20%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 5px;
              // border: 1px solid green;

              label {
                font-size: 14px;
                padding: 0;
                margin: 0;
              }

              .selected-veg {
                color: #feca03;

                button {
                  border: 1px solid #feca03;
                }
              }

              button {
                border: none;
                outline: none;
                border-radius: 38px;
              }
            }
          }
        }

        .button_field_yes {
          // margin-top: 10px;

          width: 100%;

          display: flex;
          gap: 20px;

          .selected-yes {
            background: #feca03;
            color: white;
          }

          .selected-no {
            background: #000;
            color: white;
          }

          button {
            width: 48%;
            height: 100%;
            display: flex;
            padding: 8px 4px 8px 4px;
            gap: 15px;
            border: 0.3px solid grey;
            background-color: #fff;

            // display: flex;
            justify-content: center;
            align-items: center;
            outline: none;
            border-radius: 38px;
            font-family: "RecoletaRegular";
            font-size: 20px;

            .profile_edit_button_icon {
              color: #fff;
              font-size: 32px;
              // padding: 5px;
              border-radius: 50%;
              margin-right: 15px;
              padding: 5px;
              background-color: #dcd9d985;
              // margin-right: 15px;
              font-family: "RecoletaRegular";
            }
          }
        }

        .select_breed_type {
          width: 100%;
          margin-top: 0px;
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
          border: 0.3px solid grey;
          border-radius: 22px;
          background-color: white;
          display: flex;
          gap: 20px;

          .select_breedimg {
            width: 12%;
            // border: 0.3px solid grey;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 50%;
          }
          
          .select-search-input {
            background-color: #fff;
            color: #000;
            box-shadow: none;
            width: 130%;
            border: none;
            border-radius: 0;
            margin-left: 0;
          }

          .select-search-select {
            width: 130%;
            border: none;

            .select-search-options {
              padding: 0;

              .select-search-row {
                background-color: green;

                .select-search-option {
                  background-color: #fff;
                  color: #000;
                  border-bottom: 1px solid #feca03;

                  &:hover {
                    background-color: #feca03;
                  }
                }
              }
            }
          }
        }

        .seek-bar-containers {
          // border: 1px solid red;
          width: 100%;
          margin-top: 5px;

          .form-container {
            width: 100%;
            display: flex;
            // border: 1px solid blue;
            gap: 10px;
            align-items: center;

            .seek-bar-container {
              width: 60%;
              // margin-top: 10px;
              // display: flex;
              align-items: center;
              // border: 1px solid yellow;
            }

            .seeker_button {
              width: 40%;
              //  border: 1px solid green;
              align-items: center;
              // justify-content: center;
              display: flex;
              // margin-top: 10px;
              background-color: white;
              box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
              border-radius: 38px;
              // margin-top: 20px;

              .seeker_button_continaer {
                // border: 1px solid black;
                padding: 0.25rem 1rem;
                overflow: hidden;

                width: 100%;
                display: flex;
                align-items: center;
                gap: 20px;
                border-radius: 38px;

                // background-color: white;
                // box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);

                .seek-bar-value {
                  text-transform: lowercase;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// @media screen and (max-width: "1000px") {
//   .petform {
//     width: 100%;
//     height: auto;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .petform_container {
//       width: 100%;
//       height: 100%;
//       // border: 1px solid black;

//       .flex1 {
//         display: flex;
//         justify-content: space-between;
//         gap: 5px;
//         flex-wrap: wrap;

//         .flex1container {
//           width: 100%;
//           //  border: 1px solid blue;
//           display: flex;
//           flex-direction: column;
//           gap: 10px;
//           margin-top: 1rem;

//           label {
//             font-size: 16px;

//             font-family: "RecoletaSemiBold";
//           }

//           input {
//             width: 100%;
//             border-radius: 38px;
//             padding: 10px 30px 10px 30px;
//             border: 0.3px solid grey;
//             outline: none;
//             font-family: "RecoletaRegular";
//             box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
//           }

//           .button_filed {
//             width: 100%;
//             // border: 1px solid purple;
//             display: flex;
//             gap: 20px;

//             .selected-male {
//               background-color: #feca03;
//               color: white;

//               .icon_cont {
//                 background-color: #feca03;
//               }
//             }

//             .selected-female {
//               background-color: #feca03;
//               color: white;

//               .icon_cont {
//                 background-color: #feca03;
//               }
//             }

//             button {
//               width: 45%;
//               height: 100%;
//               display: flex;
//               padding: 8px 4px 8px 4px;

//               border: none;
//               // display: flex;
//               justify-content: flex-start;
//               align-items: center;
//               outline: none;
//               border-radius: 38px;
//               font-family: "RecoletaRegular";
//               font-size: 20px;
//               background-color: #dcd9d985;
//             }
//           }

//           .pet_go_for_walk {
//             width: 100%;
//             // border: 1px solid red;
//             display: flex;
//             gap: 15px;

//             .pet_walk_select {
//               width: 45%;
//               border-radius: 38px;
//               // border: 1px solid red;
//               background-color: white;
//               border: 0.3px solid grey;

//               select {
//                 width: 90%;
//                 padding: 0.7rem;
//                 outline: none;
//                 border: none;
//                 border-radius: 38px;
//               }
//             }
//           }

//           .button_field_pet {
//             width: 100%;
//             // margin-top: 10px;

//             display: flex;
//             gap: 20px;

//             .selected-male {
//               background-color: #feca03;
//               color: white;

//               .icon_cont {
//                 background-color: #feca03;
//               }
//             }

//             .selected-female {
//               background-color: #feca03;
//               color: white;

//               .icon_cont {
//                 background-color: #feca03;
//               }
//             }

//             button {
//               width: 45%;
//               height: 100%;
//               display: flex;
//               padding: 8px 4px 8px 4px;
//               gap: 15px;
//               border: none;
//               // display: flex;
//               justify-content: flex-start;
//               align-items: center;
//               outline: none;
//               border-radius: 38px;
//               font-family: "RecoletaRegular";
//               font-size: 20px;

//               .profile_edit_button_icon {
//                 color: #fff;
//                 font-size: 32px;
//                 // padding: 5px;
//                 border-radius: 50%;
//                 margin-right: 15px;
//                 padding: 5px;
//                 background-color: rgba(151, 149, 149, 0.522);
//                 // margin-right: 15px;
//                 font-family: "RecoletaRegular";
//               }
//             }
//           }

//           .mealPlan {
//             // border: 1px solid red;
//             width: 100%;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             // margin-top: 10px;

//             .meap_plan_cont {
//               width: 100%;
//               display: flex;
//               justify-content: flex-start;
//               align-items: center;
//               //  gap: 10px;
//               // border: 1px solid blue;

//               .veg {
//                 width: 33%;
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: center;
//                 gap: 5px;
//                 //border: 1px solid green;
//                 align-items: center;

//                 label {
//                   font-size: 13px;
//                 }

//                 .selected-veg {
//                   color: #feca03; // Change this to your desired styling
//                   /* Add any other styling you want for the selected label */
//                   // box-shadow: 10px 10px 10px red  ;
//                 }

//                 button {
//                   border: none;
//                   outline: none;
//                   border-radius: 38px;
//                 }
//               }
//             }
//           }

//           .button_field_yes {
//             // margin-top: 10px;

//             width: 100%;

//             display: flex;
//             gap: 20px;

//             .selected-yes {
//               background: #feca03;
//               color: white;
//             }

//             .selected-no {
//               background: #000;
//               color: white;
//             }

//             button {
//               width: 48%;
//               height: 100%;
//               display: flex;
//               padding: 8px 4px 8px 4px;
//               gap: 15px;
//               border: none;
//               // display: flex;
//               justify-content: center;
//               align-items: center;
//               outline: none;
//               border-radius: 38px;
//               font-family: "RecoletaRegular";
//               font-size: 20px;

//               .profile_edit_button_icon {
//                 color: #fff;
//                 font-size: 32px;
//                 // padding: 5px;
//                 border-radius: 50%;
//                 margin-right: 15px;
//                 padding: 5px;
//                 background-color: rgba(151, 149, 149, 0.522);
//                 // margin-right: 15px;
//                 font-family: "RecoletaRegular";
//               }
//             }
//           }

//           .select_breed_type {
//             width: 95%;
//             margin-top: 0px;
//             box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
//             border: 0.3px solid grey;
//             border-radius: 22px;
//             background-color: white;
//             display: flex;
//             gap: 20px;

//             .select_breedimg {
//               width: 12%;
//               // border: 0.3px solid grey;
//               display: flex;
//               justify-content: center;
//               align-items: center;
//               border: 50%;
//             }

//             select {
//               width: 80%;
//               border-radius: 22px;

//               padding: 10px 30px 10px 30px;
//               border: none;
//               outline: none;
//               font-family: "RecoletaRegular";
//             }
//           }

//           .seek-bar-containers {
//             // border: 1px solid red;
//             width: 100%;
//             margin-top: 5px;

//             .form-container {
//               width: 95%;
//               display: flex;
//               // border: 1px solid blue;
//               gap: 10px;
//               align-items: center;

//               .seek-bar-container {
//                 width: 60%;
//                 // margin-top: 10px;
//                 // display: flex;
//                 align-items: center;
//                 // border: 1px solid yellow;
//               }

//               .seeker_button {
//                 width: 40%;
//                 //  border: 1px solid green;
//                 align-items: center;
//                 // justify-content: center;
//                 display: flex;
//                 // margin-top: 10px;
//                 background-color: white;
//                 box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
//                 border-radius: 38px;
//                 // margin-top: 20px;

//                 .seeker_button_continaer {
//                   // border: 1px solid black;

//                   width: 85%;
//                   display: flex;
//                   align-items: center;
//                   gap: 20px;
//                   border-radius: 38px;

//                   // background-color: white;
//                   // box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);

//                   .seek-bar-value {
//                     text-transform: lowercase;
//                     font-family: "RecoletaRegular";
//                     font-size: 15px;
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: "550px") {
  .petform {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .petform_container {
      width: 100%;
      height: 100%;
      // border: 1px solid black;

      .flex1 {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        flex-wrap: wrap;

        .flex1container {
          width: 100%;
          //  border: 1px solid blue;
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-top: 1rem;

          label {
            font-size: 16px;
            margin-left: 10px;
            font-family: "RecoletaSemiBold";
          }

          input {
            width: 100%;
            border-radius: 38px;
            padding: 10px 30px 10px 30px;
            border: 0.3px solid grey;
            outline: none;
            font-family: "RecoletaRegular";
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
          }

          .datepicker {
            input {
              width: 120%;
              // background-color: orange;
            }
          }

          .button_filed {
            width: 100%;
            // border: 1px solid purple;
            display: flex;
            gap: 20px;

            .selected-male {
              background-color: #feca03;
              color: white;

              .icon_cont {
                background-color: #feca03;
              }
            }

            .selected-female {
              background-color: #feca03;
              color: white;

              .icon_cont {
                background-color: #feca03;
              }
            }

            button {
              width: 45%;
              height: 100%;
              display: flex;
              padding: 8px 4px 8px 4px;

              border: none;
              // display: flex;
              justify-content: flex-start;
              padding-left: 10px;
              align-items: center;
              outline: none;
              border-radius: 38px;
              font-family: "RecoletaRegular";
              font-size: 16px;
              background-color: #dcd9d985;
            }
          }

          .pet_go_for_walk {
            width: 100%;
            // border: 1px solid red;
            display: flex;
            gap: 15px;

            .pet_walk_select {
              width: 50%;
              border-radius: 38px;
              // border: 1px solid red;
              background-color: white;
              border: 0.3px solid grey;
              padding-right: 5px;

              select {
                width: 100%;
                padding: 0.7rem;
                outline: none;
                border: none;
                border-radius: 38px;
                background: transparent;
                font-size: 14px;
              }
            }
          }

          .button_field_pet {
            width: 100%;
            // margin-top: 10px;

            display: flex;
            gap: 20px;

            .selected-male {
              background-color: #feca03;
              color: white;

              .icon_cont {
                background-color: #feca03;
              }
            }

            .selected-female {
              background-color: #feca03;
              color: white;

              .icon_cont {
                background-color: #feca03;
              }
            }

            button {
              width: 45%;
              height: 100%;
              display: flex;
              padding: 8px 4px 8px 4px;
              gap: 15px;
              border: none;
              // display: flex;
              justify-content: flex-start;
              align-items: center;
              outline: none;
              border-radius: 38px;
              font-family: "RecoletaRegular";
              font-size: 16px;

              .profile_edit_button_icon {
                color: #fff;
                font-size: 32px;
                // padding: 5px;
                border-radius: 50%;
                margin-right: 15px;
                padding: 5px;
                background-color: #dcd9d985;
                // margin-right: 15px;
                font-family: "RecoletaRegular";
              }
            }
          }

          .mealPlan {
            // border: 1px solid red;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            // margin-top: 10px;

            .meap_plan_cont {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              //  gap: 10px;
              // border: 1px solid blue;

              .veg {
                width: 33%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 5px;
                //border: 1px solid green;
                align-items: center;

                label {
                  font-size: 13px;
                }

                .selected-veg {
                  color: #feca03; // Change this to your desired styling
                  /* Add any other styling you want for the selected label */
                  // box-shadow: 10px 10px 10px red  ;
                }

                button {
                  border: none;
                  outline: none;
                  border-radius: 38px;
                }
              }
            }
          }

          .button_field_yes {
            // margin-top: 10px;

            width: 100%;

            display: flex;
            gap: 20px;

            .selected-yes {
              background: #feca03;
              color: white;
            }

            .selected-no {
              background: #000;
              color: white;
            }

            button {
              width: 48%;
              height: 100%;
              display: flex;
              padding: 8px 4px 8px 4px;
              gap: 15px;
              border: none;
              // display: flex;
              justify-content: center;
              align-items: center;
              outline: none;
              border-radius: 38px;
              font-family: "RecoletaRegular";
              font-size: 16px;

              .profile_edit_button_icon {
                color: #fff;
                font-size: 32px;
                // padding: 5px;
                border-radius: 50%;
                margin-right: 15px;
                padding: 5px;
                background-color: #dcd9d985;
                // margin-right: 15px;
                font-family: "RecoletaRegular";
              }
            }
          }

          .select_breed_type {
            width: 95%;
            margin-top: 0px;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
            border: 0.3px solid grey;
            border-radius: 22px;
            background-color: white;
            display: flex;
            gap: 20px;
            padding-right: 15px;

            .select_breedimg {
              width: 50px;
              padding: 4px;
              // border: 0.3px solid grey;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 50%;
            }

            select {
              width: 80%;
              border-radius: 22px;
              background: transparent;
              padding: 10px 30px 10px 30px;
              border: none;
              outline: none;
              font-family: "RecoletaRegular";
            }
          }

          .seek-bar-containers {
            // border: 1px solid red;
            width: 100%;
            margin-top: 5px;

            .form-container {
              width: 95%;
              display: flex;
              // border: 1px solid blue;
              gap: 10px;
              align-items: center;

              .seek-bar-container {
                width: 60%;
                // margin-top: 10px;
                // display: flex;
                align-items: center;
                // border: 1px solid yellow;
              }

              .seeker_button {
                width: 40%;
                //  border: 1px solid green;
                align-items: center;
                // justify-content: center;
                display: flex;
                // margin-top: 10px;
                background-color: white;
                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
                border-radius: 38px;
                // margin-top: 20px;

                .seeker_button_continaer {
                  // border: 1px solid black;

                  width: 85%;
                  display: flex;
                  align-items: center;
                  gap: 20px;
                  border-radius: 38px;

                  // background-color: white;
                  // box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);

                  .seek-bar-value {
                    text-transform: lowercase;
                    font-family: "RecoletaRegular";
                    font-size: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
