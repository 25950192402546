* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.community_home {
  width: 100%;
  height: 100vh;
  // background-color: orange;
  position: relative;

  .community_home_sidebar {
    width: 300px;
    height: calc(100vh - 70px);
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 110px;

    .sidebar_categories_wrapper {
      width: 100%;
      height: auto;
      list-style-type: none;
      padding: 0;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0px;

      .categories {
        width: 100%;
        background-color: #000;
        color: #fff;
        font-size: 22px;
        height: max-content;
        padding: 0.5rem 0;
        padding-left: 80px;
        text-transform: uppercase;
      }

      .selected {
        background-color: #feca05;
      }

      .category {
        width: 100%;
        height: max-content;
        font-size: 20px;
        padding: 0.5rem 0;
        padding-left: 80px;
        border-bottom: 1px solid #fff;

        &:hover {
          background-color: #feca05;
          cursor: pointer;
        }

        a {
          width: 100%;
          height: 40px;
          // border: 1px solid green;
          // padding: 4px 4px 4px 4px;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 10px;
          text-decoration: none;
          gap: 20px;
          color: black;

          h5 {
            font-family: "RecoletaRegular";
            font-size: 18px;
            margin: 0;
          }
        }
      }
    }
  }

  .community_home_body {
    position: absolute;
    left: 300px;
    width: calc(100% - 300px);
    // background-color: aqua;
    height: max-content;
    padding-top: 140px;
  }
}
