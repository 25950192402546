* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.host-card-wrapper {
  width: 19rem;
  height: 25rem;
  border-radius: 30px;
  padding: 1rem;
  gap: 0.7rem;
  flex-direction: column;
  background-color: #ffffff;
  margin-right: 1rem;

  &:hover {
    transform: scale(1.03);
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .host-image-container {
    width: 100%;
    max-height: 50%;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    // border: 1px solid black;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .add-to-fav {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      padding: 0.5rem;
      background-color: #000000a0;
      border-radius: 50%;
      color: #feca05;
      color: #fff;

      .add-to-fav-icon {
        font-size: 18px;
      }
    }
  }

  .host-info-container {
    width: 100%;
    height: 50%;
    flex-direction: column;

    .host-info {
      width: 100%;
      height: 80%;

      .ratings-wrapper {
        width: 100%;
        justify-content: flex-start;
        gap: 0.5rem;
        margin-bottom: 0.3rem;

        .rating-stars {
          display: flex;
          gap: 0.3rem;

          .star {
            color: #feca03;
            font-size: 18px;
          }
        }

        .rating {
          font-size: 14px;
        }
      }

      .name {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 0.2rem;
      }

      .profile,
      .location {
        font-size: 14px;
        color: #939393;
        margin: 0;
        margin-bottom: 0.2rem;
      }

      .price {
        font-size: 16px;
        margin: 0;
        margin-bottom: 0.2rem;

        span {
          font-size: 14px;
        }
      }
    }

    .book-now-button-wrapper {
      width: 100%;
      height: 20%;

      #book-now-button {
        width: 100%;
        height: 100%;
        background-color: #feca03;
        border: none;
        outline: none;
        border-radius: 27px;
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
}
