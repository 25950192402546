@import "./variables";
@import "./mixins";

body {
  margin: 0;
  padding: 0;
  background-color: $background-color;
}

.dashboard {
  padding: 20px;
  .dashboard-title {
    color: $primary-color;
    margin-bottom: 20px;
  }

  .summary-container {
    @include flex-center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .summary-card {
    background: $card-color;
    border-radius: 10px;
    padding: 20px;
    width: 150px;
    text-align: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

    h2 {
      color: $primary-color;
      margin: 0;
    }

    p {
      font-size: 1.5rem;
      margin: 5px 0 0;
    }
  }
}
