* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .checkout {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    .checkout-heading {
      width: 100%;
      text-align: center;
  
      h4 {
        font-size: 28px;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding: 0.5rem;
      }
    }
  
    .checkout-form {
      width: 100%;
      max-width: 500px;
      background-color: #f9f9f9;
      padding: 1.5rem;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin-top: 2rem;
  
      .form-group {
        margin-bottom: 1.5rem;
  
        label {
          display: block;
          margin-bottom: 0.5rem;
          font-size: 16px;
          color: #333;
        }
  
        input[type="text"],
        input[type="number"],
        input[type="date"],
        input[type="time"],
        input[type="file"],
        textarea {
          width: 100%;
          padding: 0.75rem;
          font-size: 16px;
          border: 1px solid #ccc;
          border-radius: 5px;
          outline: none;
          transition: border-color 0.3s ease;
  
          &:focus {
            border-color: #007bff;
          }
        }
  
        textarea {
          resize: vertical;
        }
      }
      .save-btn {
        display: inline-block;
        width: 100%;
        padding: 0.75rem;
        background-color: #feca03;
        color: #fff;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  