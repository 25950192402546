* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.invoice-page-wrapper {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2.5rem;

  .main_container {
    width: 794px;
    height: 1123px;
    padding: 6rem 4rem;
    background-color: #feca05;
    background-color: #fff;
    border: 1px solid #feca05;

    .heading_container {
      width: 100%;
      height: max-content;
      // background-color: green;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #feca05;

      .heading_container_left {
        width: 50%;
        height: max-content;

        img {
          width: 50%;
          margin-bottom: 1rem;
        }
      }

      .heading_container_right {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        p {
          font-size: 34px;
        }
      }
    }

    .info_container {
      width: 100%;
      height: max-content;
      // background-color: red;
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem;
      margin-bottom: 0;
      border-bottom: 2px solid #feca05;

      p {
        margin: 0;
      }
    }

    table {
      width: 100%;
      height: max-content;
      margin-top: -1rem;
      // background-color: green;

      thead {
        border-bottom: 1px solid #feca05;
        // background-color: rebeccapurple;
        width: 100%;

        tr {
          display: flex;
          justify-content: space-between;
          // background-color: yellowgreen;
          width: 100%;
          padding-right: 15px;

          th {
            // background-color: yellowgreen;
          }
        }
      }

      tbody {
        // background-color: orange;

        tr {
          border-bottom: 1px solid #feca05;
          display: flex;
          justify-content: space-between;
          padding-right: 15px;
          padding-top: 5px;
        }
      }
    }
  }

  .btn-wrapper {
    .button_btn {
      margin-right: 1rem;
      margin-top: 1rem;
      padding: 5px 15px;
      border-radius: 27px;
      background-color: #fff;
      border: 1px solid #feca05;
      transition: 0.5s;

      &:hover {
        background-color: #feca05;
      }
    }

    .input_input {
      margin-right: 1rem;
      border-radius: 10px;
      padding: 5px 10px;
    }
  }
}
