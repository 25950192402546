* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.community_posts_wrapper {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .community_posts {
    width: 50%;
    height: max-content;

    .add_post_wrapper {
      width: 100%;
      height: max-content;
      background-color: #fff;
      padding: 1.5rem;
      border-radius: 25px;

      .only_caption_input_container {
        height: max-content;
        width: 100%;
        border-radius: 27px;
        background-color: #ededed;
        border: none;
        outline: none;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: orange;

        input {
          width: 100%;
          padding: 0.7rem 1.5rem;
          border-radius: 27px;
          background-color: #ededed;
          border: none;
          outline: none;
          margin-right: 1rem;
        }

        .post_only_caption {
          padding: 0.3rem 1.5rem;
          border-radius: 27px;
          background-color: #ededed;
          border: none;
          outline: none;
          // background-color: #FECA05;
          background-color: #fff;
          margin-right: 0.4rem;
        }
      }

      .add_post_buttons {
        button {
          padding: 0.5rem 1.5rem;
          border: none;
          outline: none;
          border-radius: 20px;
          margin-right: 1rem;
          background-color: #feca05;

          &:nth-child(2) {
            background: linear-gradient(to right, #03feb3, #03effe);
            // background-color: green;
          }

          .btn_icons {
            font-size: 26px;
            margin-right: 7px;
          }
        }
      }
    }

    .all_posts_wrapper {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 2.5rem;

      .post {
        width: 100%;
        height: max-content;
        margin-top: 1.5rem;
        background-color: #fff;
        padding: 1rem 5%;
        border-radius: 20px;
        position: relative;
        overflow: hidden;
        // border: 1px solid #FECA05;

        .top_container {
          width: 100%;
          height: 65px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // border: 1px solid red;

          .profile_img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .name_date {
            width: calc(95% - 75px);
            padding-left: 0.5rem;

            p {
              margin: 0;
              padding: 0;

              &:nth-child(1) {
                font-size: 20px;
                font-weight: 500;
              }

              &:nth-child(2) {
                font-size: 12px;
              }
            }
          }

          .action_btn {
            font-size: 32px;
          }
        }

        .caption_container {
          width: 100%;
          max-height: max-content !important;
          // background-color: green;
          overflow: scroll;
          margin-top: 10px;
          font-size: 14px;
          color: #000;
          &::-webkit-scrollbar {
            display: none; // Hide the scrollbar for WebKit browsers (Chrome, Safari, etc.)
          }

          p {
            padding: 0;
            margin: 0;

            span {
              color: #302d2d;
              &:hover {
                cursor: pointer;
                text-decoration: underline;
              }
            }
          }
        }

        .options_container {
          width: 100%;
          height: max-content;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: center;
          justify-content: center;
          margin-top: 1rem;

          .options {
            width: 100%;
            padding: 0.7rem;
            border: none;
            outline: none;
            border-radius: 27px;
          }
        }

        .answered_options_container {
          width: 100%;
          height: max-content;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: center;
          justify-content: center;
          margin-top: 1rem;

          .options {
            width: 100%;
            height: calc(1.4rem + 20px);
            padding: 0.7rem;
            border: none;
            outline: none;
            border-radius: 27px;
            position: relative;
            background: transparent;
            overflow: hidden;
            background-color: #f8f8f8;

            .text_opt_val {
              position: absolute;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 1.5rem;
              top: 0;
              left: 0;
              z-index: 1;
              text-transform: capitalize;
            }

            .coloured_highlight {
              background-color: #feca05;
              width: 80%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 0;
            }
          }
        }

        .post_main_image_container {
          max-width: 100%;
          height: max-content;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border-radius: 0px;
          margin-top: 10px;
          // background-color: orange;

          .carousel-inner {
            width: max-content;
            height: max-content;
            justify-content: center;
            // border: 1px solid blue;

            .carousel-item {
              width: 100%;
              height: max-content;
              // border: 2px solid red;

              // img,
              // video {
              //   width: 100%;
              //   height: 100%;
              //   display: block;
              // }
            }
          }

          .carousel_button {
            height: 40px;
            width: 40px;
            top: 50%;
            transform: translateY(-50%);
            background-color: #000;
            border-radius: 50%;
            font-size: 14px;
          }
        }

        .user_action_btns {
          width: 100%;
          height: 70px;
          // background-color: #03effe;
          margin-top: 10px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;

          .like,
          .comment,
          .share {
            margin-right: 1.5rem;
            .action_icon {
              font-size: 30px;

              &:hover {
                cursor: pointer;
              }
            }
          }

          .like {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            gap: 0.2rem;
            margin-right: 0.7rem;

            span {
              font-size: 14px;

              &:hover {
                color: blue;
                cursor: pointer;
              }
            }

            .liker_container {
              position: absolute;
              left: 0;
              max-height: 90%;
              width: 60%;
              z-index: 9;
              background-color: #feca05;
              border-radius: 20px;
              padding: 1rem;
              overflow-x: scroll;
              transition: 0.5s ease;

              .header_cls_btn {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0.5rem;
                border-bottom: 1px solid black;
                padding: 0 0.5rem;
                position: sticky;
                top: -1rem;
                left: -1rem;
                background-color: #feca05;
                padding: 0.5rem 0;

                p {
                  margin: 0;
                  padding: 0;
                  font-size: 16px;
                }

                .icon {
                  font-size: 20px;
                  cursor: pointer;
                }
              }

              ul {
                margin: 0;
                padding: 0;
                margin-top: 0rem;
                li {
                  list-style-type: none;
                  margin-bottom: 0.5rem;
                  padding: 0;

                  img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                  }

                  span {
                    font-size: 18px;
                    margin-left: 0.5rem;
                  }
                }
              }
            }
          }

          .comment {
            .commenter_container {
              position: absolute;
              left: 0;
              max-height: 90%;
              width: 100%;
              z-index: 9;
              background-color: #fff;
              border-radius: 20px;
              padding: 0 1rem;
              overflow-x: scroll;
              transition: 0.5s ease;

              .header_cls_btn {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0.5rem;
                border-bottom: 1px solid black;
                padding: 0 0.5rem;
                position: sticky;
                top: 0rem;
                left: -1rem;
                background-color: #fff;
                padding: 1rem 0.5rem 0.5rem 0.5rem;

                p {
                  margin: 0;
                  padding: 0;
                  font-size: 18px;
                  font-weight: 600;
                }

                .icon {
                  font-size: 20px;
                  cursor: pointer;
                }
              }

              .comments_wrapper {
                width: 100%;
                height: max-content;

                .single_comment {
                  width: 100%;
                  height: max-content;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: flex-start;
                  margin-bottom: 1rem;

                  .single_comment_contents {
                    width: 100%;
                    height: max-content;
                    display: flex;
                    gap: 0.5rem;
                    align-items: flex-start;
                    justify-content: flex-start;

                    img {
                      width: 30px;
                      height: 30px;
                      border-radius: 50%;
                      margin-top: 0.2rem;
                    }

                    .name_nd_comment {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      justify-content: flex-start;
                      margin-bottom: 0.3rem;
                      // gap: 0.2rem;
                      p {
                        margin: 0;
                        padding: 0;
                      }

                      .commenter_name {
                        font-size: 16px;
                        font-weight: 500;
                      }

                      .text_cmt {
                        font-size: 14px;
                      }
                    }
                  }

                  .single_comment_actions {
                    width: 100%;
                    height: max-content;
                    display: flex;
                    gap: 1rem;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding-left: 40px;

                    p {
                      margin: 0;
                      padding: 0;
                      font-size: 12px;
                      color: #111111;
                    }

                    .replied_on {
                      text-decoration: none;
                    }

                    .all_likes {
                      text-decoration: none;
                    }

                    .reply_comment {
                      &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                      }
                    }

                    .report_comment {
                      &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                      }
                    }
                  }

                  .post_reply {
                    padding-left: 40px;
                    padding-right: 40px;
                    margin-top: 0.7rem;
                    width: 100%;
                    height: max-content;
                    background-color: #fff;
                    position: sticky;
                    bottom: 0rem;
                    left: 0;
                    padding-bottom: 0.5rem;
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    justify-content: flex-start;
                    // display: none;

                    input[type="text"] {
                      font-size: 14px;
                      width: 85%;
                      padding: 0.5rem 1rem;
                      outline: none;
                      border: none;
                      border-radius: 27px;
                      background-color: #f8f8f8;
                      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
                    }

                    input[type="submit"] {
                      padding: 0.3rem 0.8rem;
                      outline: none;
                      border: 1px solid black;
                      border-radius: 5px;
                      font-size: 14px;
                    }
                  }

                  .all_reply_container {
                    padding-left: 40px;
                    padding-top: 0.5rem;

                    .single_reply {
                      display: flex;
                      flex-direction: column;
                    }

                    .view_all_replies_btn {
                      font-size: 12px;
                      margin: 0;
                      margin-bottom: 0.3rem;
                      user-select: none;

                      &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }

              .post_comment {
                width: 100%;
                height: max-content;
                background-color: #fff;
                position: sticky;
                bottom: 0rem;
                left: 0;
                padding-bottom: 0.5rem;
                display: flex;
                gap: 1rem;
                align-items: center;
                justify-content: flex-start;

                input[type="text"] {
                  width: 85%;
                  padding: 0.7rem 1rem;
                  outline: none;
                  border: none;
                  border-radius: 27px;
                  background-color: #f8f8f8;
                  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
                }

                input[type="submit"] {
                  padding: 0.3rem 0.8rem;
                  outline: none;
                  border: 1px solid black;
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.browse_file_modal {
  background-color: #222222 !important;
  border-radius: 27px;

  .modal-body {
    width: 100%;
    height: max-content;
    padding: 1.3rem;

    .categoryheader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;

      p {
        color: #fff;
        margin: 0;
        padding: 0;
        margin-left: 0.5rem;
        font-size: 16px;
      }

      button {
        background: transparent;
        outline: none;
        border: none;
        color: #fff;
        font-size: 24px;
      }
    }

    .category_container {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 0.5rem;

      input[type="radio"] {
        display: none;
      }

      label {
        height: 40px;
        padding: 0.3rem 1.4rem;
        border-radius: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        border: none;
        background-color: #ffffff28;
        gap: 10px;
        color: #fff;

        &:hover {
          cursor: pointer;
        }

        img {
          width: 25px;
        }

        p {
          margin: 0;
          padding: 0;
          font-weight: 400;
          font-size: 16px;
        }
      }

      input[type="radio"]:checked + label {
        background-color: #fff;
        color: #000;
      }
    }

    .caption_container {
      width: 100%;
      border-bottom: 1px solid #fff;
      margin-top: 1rem;

      input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        padding: 0.5rem 0.3rem;
        color: #fff;
      }
    }

    .options_container_to_create {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      justify-content: flex-start;
      padding: 1.5rem 0;

      .option_input {
        width: 100%;
        border: none;
        outline: none;
        background-color: #ffffffd1;
        border: 1px solid #fff;
        border-radius: 27px;
        position: relative;

        &:nth-child(5) {
          cursor: pointer;

          input {
            cursor: pointer;
          }
        }

        input {
          width: 100%;
          height: 100%;
          padding: 0.5rem 0.5rem;
          border-radius: 27px;
          text-align: center;
          background: transparent;
          border: none;
          outline: none;

          &:nth-child(3),
          &:nth-child(4) {
            padding-right: calc(1% + 0.6rem + 16px);
          }

          &::placeholder {
            color: #00000081;
          }
        }

        .remove_poll_option {
          position: absolute;
          right: 1%;
          top: 50%;
          transform: translate(0, -50%);
          width: max-content;
          height: max-content;
          background-color: #000000ab;
          color: #fff;
          display: flex;
          align-content: center;
          justify-content: center;
          border-radius: 50%;
          padding: 0.3rem;

          &:hover {
            cursor: pointer;
            background-color: #000000;
          }

          .remove_poll_option_icon {
            margin: 0;
            padding: 0;
            font-size: 16px;
          }
        }
      }
    }

    .img_vid_input {
      width: 100%;
      height: max-content;
      display: flex;
      gap: 1.5rem;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      // border: 1px solid #fff;
      padding: 1.5rem 0;

      .image_input_wrapper,
      .video_input_wrapper {
        width: 100%;
        height: max-content;
        color: #fff;

        p {
          margin: 0;
          width: max-content;
          font-size: 16px;
          margin-bottom: 0.2rem;

          span {
            font-size: 12px;
          }
        }

        input {
          margin: 0;
          font-size: 14px;
          cursor: pointer;
          // background-color: orange;
          width: 48%;
        }
      }

      .img_drag_and_drop_preview_container {
        width: 100%;
        height: max-content;

        .heading_wrapper {
          width: 100%;
          height: max-content;
          color: #fff;

          p {
            margin: 0;
            width: max-content;
            font-size: 16px;
            margin-bottom: 0.2rem;

            span {
              font-size: 12px;
            }
          }
        }

        .dropzone {
          height: 70px;
          border-radius: 5px;
          border: 2px dashed #000;
          color: #000;
          background-color: #f4f3f9;
          display: flex;
          justify-content: center;
          align-items: center;
          user-select: none;
          -webkit-user-select: none;
          margin-top: 10px;
          cursor: pointer;

          .select {
            color: blue;
            margin-left: 0.2rem;

            &:hover {
              text-decoration: underline;
            }
          }

          .visible {
            font-size: 18px;
          }

          input {
            display: none;
          }
        }

        .preview_container {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;
          max-height: 200px;
          overflow-y: auto;
          margin-top: 10px;

          .preview_image {
            width: 100px;
            margin-right: 5px;
            height: 100px;
            position: relative;
            margin-bottom: 8px;
            overflow: hidden;

            img,
            video {
              width: 100%;
              height: auto;
              border-radius: 5px;
            }

            .remove_preview {
              position: absolute;
              top: 5px;
              right: 5px;
              border-radius: 50%;
              cursor: pointer;
              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.2rem;

              .cross_icon {
                font-size: 14px;
                margin: 0;
                padding: 0;
                font-weight: bold;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .selected_image_container {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 4%;
      margin-top: 1rem;

      .image {
        width: 48%;
        height: 200px;
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 1rem;
        position: relative;

        button {
          outline: none;
          border: none;
          color: #fff;
          font-size: 20px;
          position: absolute;
          top: 5px;
          right: 5%;
          background-color: #0000003d;
          border-radius: 50%;
          width: 30px;
          height: 30px;
        }

        img {
          width: 100%;
        }
      }
    }

    .action_btn_container {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .action_btn {
        height: 45px;
        padding: 0.3rem 1.4rem;
        border-radius: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        border: none;
        background-color: #ffffff28;
        gap: 10px;
        color: #fff;
        font-size: 16px;

        img {
          width: 25px;
        }

        p {
          margin: 0;
          padding: 0;
        }

        &:nth-child(2) {
          padding: 0.3rem 2rem;
          background-color: #fff;
          color: #000;
        }
      }
    }
  }

  .not_logged_in_wrapper {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #feca05;
    border-radius: 27px;
    margin: 0;
    padding: 2.5rem 4rem;

    .not_logged_in_text {
      font-size: 18px;
      text-align: center;

      .heading {
        font-size: 20px;
        font-weight: 500;
        line-height: 3rem;
      }
    }

    .not_logged_in_btn_wrapper {
      width: 100%;
      height: max-content;
      display: flex;
      gap: 1.5rem;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;

      button {
        padding: 0.4rem 1.5rem;
        border-radius: 27px;
        border: none;
        outline: none;
        font-weight: 500;
        box-shadow: 1px 1px 10px #0000002b;
        background-color: #fff;
      }

      .cancel {
        background-color: #ffffff;
      }
    }
  }
}
