* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.loading-wrapper {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;

  .loader {
    width: 75px;
    height: 75px;
    border: 1px dashed black;
    border-radius: 50%;
    padding: 3px;
    animation: rotate 0.9s linear infinite;
  // animation: moveAndRotate 2s linear infinite;

    img {
      width: 100%;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveAndRotate {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  20% {
    transform: translate(0, -100px) rotate(72deg);
  }
  40% {
    transform: translate(100px, -100px) rotate(144deg);
  }
  60% {
    transform: translate(000px, 100px) rotate(216deg);
  }
  80% {
    transform: translate(-100px, 100px) rotate(288deg);
  }
  90% {
    transform: translate(-100px, 100px) rotate(324deg);
  }
  100% {
    transform: translate(0, 100px) rotate(360deg);
  }
}

