* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.logout-confirm-popup-buttons {
  border: none;
  outline: none;
  width: 100px;
  padding: 7px 20px;
  border-radius: 27px;

  &:first-child {
    background-color: #000;
    color: #fff;
  }

  &:last-child {
    background-color: #FECA05;
    color: #000;
  }
}

.popup-title {
  width: 100%;
  height: max-content;
  flex-direction: column;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid black;
  }

  p {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 22px;
    font-weight: 600;
    margin-top: 10px;
  }
}

.header-container {
  width: 100%;
  height: 65px;
  position: fixed;
  top: 0;
  z-index: 999;

  .top-header-section {
    width: 100%;
    height: 65px;
    background-color: #feca03;
    padding: 0 5%;

    .top-left-container {
      height: 100%;
      width: 10%;
      justify-content: flex-start;
      // background-color: red;

      img {
        width: 100%;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .top-mid-container {
      width: 80%;
      height: 100%;
      justify-content: flex-start;
      padding-left: 2.5rem;
      // background-color: orange;

      .my-location-wrapper {
        position: relative;
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin: 0 1.5rem;
      }

      .menu {
        height: 100%;
        width: max-content;
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;

        .sec_change_btn {
          height: 70%;
          padding-top: 7px;
          // padding: 0 20px;
          padding-bottom: 0;
          padding-left: 20px;
          padding-right: 20px;
          box-sizing: border-box;
          font-size: 18px;
          text-decoration: none;
          color: #000;
          display: flex;
          align-items: center;
          // background-color: #fff;

          &.active {
            border-bottom: 2px solid black;
          }

          &:hover {
            cursor: pointer;
            border-bottom: 2px solid black;
          }

          .btn_icon {
            font-size: 24px;
            margin-right: 7px;
            margin-bottom: 4px;
          }
        }
      }
    }

    .top-right-container {
      height: 100%;
      width: 10%;
      justify-content: flex-end;
      gap: 1.5rem;
      // background-color: blue;

      .profile-icon-container {
        width: 40px;
        height: 40px;
        background-color: #fff;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .menu-btn-container {
        width: max-content;
        height: 100%;

        .menu-btn-icon {
          font-size: 26px;
          cursor: pointer;
        }
      }
    }
  }

  .bottom-header-section {
    width: 100%;
    height: 45px;
    background-color: #fff;

    .menu {
      height: 100%;
      width: 100%;
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;

      .sec_change_btn {
        height: 70%;
        padding-top: 7px;
        // padding: 0 20px;
        padding-bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        font-size: 18px;
        text-decoration: none;
        color: #000;
        display: flex;
        align-items: center;
        // background-color: #fff;

        &.active {
          border-bottom: 2px solid black;
        }

        &:hover {
          cursor: pointer;
          border-bottom: 2px solid black;
        }

        .btn_icon {
          font-size: 24px;
          margin-right: 7px;
          margin-bottom: 4px;
        }
      }
    }
  }
}

.sidebar-container {
  width: 20rem;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  right: 0;
  transition: 0.5s ease;
  padding: 2rem;
  z-index: 9999;

  .close-sidebar-btn-container {
    width: 100%;
    height: max-content;
    margin-bottom: 1rem;

    .close-sidebar-btn {
      font-size: 24px;
      color: #fff;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .user-info-card {
    background-color: #feca03;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 7px 5px;
    gap: 5px;
    border-radius: 15px;
    cursor: pointer;

    .profile-img {
      width: 45px;
      height: 45px;
      border: 1px solid #fff;
      padding: 1px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .user-info {
      width: calc(100% - 45px);
      height: max-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      overflow: hidden;

      p {
        margin: 0;
        padding: 0;
      }

      .user-name {
        font-weight: 600;
      }

      .user-email {
        font-size: 11px;
      }
    }
  }

  .navigation-links-list {
    width: 100%;
    height: max-content;
    padding: 0;
    margin-top: 1.5rem;

    .navigation-link {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      list-style-type: none;
      color: #fff;
      margin-bottom: 1rem;

      .nav-link-icon {
        font-size: 22px;
        font-weight: 900;
        margin-bottom: 0.15rem;
        margin-right: 0.5rem;
      }

      a {
        color: #fff;
        font-size: 15px;
        text-decoration: none;
        transition: 0.5s;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .side-bar-social-icons {
    width: 100%;
    height: max-content;
    justify-content: flex-start;
    gap: 1rem;
    position: absolute;
    bottom: 2.5rem;

    .social-icon {
      padding: 0.25rem;
      background-color: #000;
      border-radius: 50%;
      transition: 0.5s;

      &:hover {
        border-radius: 5px;
      }

      a {
        margin: 0;
        padding: 0;
        text-decoration: none;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          color: #fff;
          font-size: 20px;
        }
      }
    }

    .instagram {
      background: radial-gradient(
        circle at 0% 0%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%
      );
    }

    .facebook {
      background-color: #0765ff;
    }

    .linkedin {
      background-color: #0173b1;
    }
  }
}

/* Extra small devices (phones, 0px and up) */
@media only screen and (max-width: 575.98px) {
  .header-container {
    width: 100%;
    height: 65px;
    position: fixed;
    top: 0;
    z-index: 999;

    .top-header-section {
      width: 100%;
      height: 65px;
      background-color: #feca03;
      padding: 0 7.5%;
      justify-content: space-between;

      .top-left-container {
        height: 100%;
        width: 40%;
        justify-content: flex-start;
        // background-color: red;

        img {
          width: 100%;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .top-mid-container {
        display: none;
      }

      .top-right-container {
        height: 100%;
        width: 10%;
        justify-content: flex-end;
        gap: 1.5rem;
        // background-color: blue;

        .profile-icon-container {
          width: 40px;
          height: 40px;
          background-color: #fff;
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .menu-btn-container {
          width: max-content;
          height: 100%;

          .menu-btn-icon {
            font-size: 26px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .sidebar-container {
    width: 18rem;
    height: 100vh;
    background-color: #000;
    position: fixed;
    top: 0;
    right: 0;
    transition: 0.5s ease;
    padding: 2rem;
    z-index: 9999;

    .close-sidebar-btn-container {
      width: 100%;
      height: max-content;

      .close-sidebar-btn {
        font-size: 24px;
        color: #fff;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .navigation-links-list {
      width: 100%;
      height: max-content;
      padding: 0;
      margin-top: 2.5rem;

      .navigation-link {
        width: 100%;
        height: max-content;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        list-style-type: none;
        color: #fff;
        margin-bottom: 1rem;

        .nav-link-icon {
          font-size: 22px;
          font-weight: 900;
          margin-bottom: 0.15rem;
          margin-right: 0.5rem;
        }

        a {
          color: #fff;
          font-size: 18px;
          text-decoration: none;
          transition: 0.5s;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .side-bar-social-icons {
      width: 100%;
      height: max-content;
      justify-content: flex-start;
      gap: 1rem;
      position: absolute;
      bottom: 2.5rem;

      .social-icon {
        padding: 0.25rem;
        background-color: #000;
        border-radius: 50%;
        transition: 0.5s;

        &:hover {
          border-radius: 5px;
        }

        a {
          margin: 0;
          padding: 0;
          text-decoration: none;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            color: #fff;
            font-size: 20px;
          }
        }
      }

      .instagram {
        background: radial-gradient(
          circle at 0% 0%,
          #fdf497 0%,
          #fdf497 5%,
          #fd5949 45%,
          #d6249f 60%,
          #285aeb 90%
        );
      }

      .facebook {
        background-color: #0765ff;
      }

      .linkedin {
        background-color: #0173b1;
      }
    }
  }
}
.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 7px 11px;
  background-color: #feca05;
  .notifications {
    position: relative;
    cursor: pointer;

    .notification-bell {
      cursor: pointer;
      position: relative;

      .bell-icon {
        font-size: 1.0rem;
        color:white !important;
      }

      .badge {
        background-color: red;
        color: white;
        border-radius: 50%;
        padding: 0.3em 0.5em;
        position: absolute;
        top: -6px;
        right: -8px;
        font-size: 0.6rem;
      }
    }

    .dropdown {
      position: absolute;
      top: 45px;
      right: 0;
      background: white;
      border: 1px solid #ddd;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      width: 426px;
      max-height: 496px;
      overflow-y: auto;
      z-index: 10;
      padding: 10px;

      h4 {
        margin: 0 0 10px 0;
      }

      ul {
        list-style: none;
        padding: 0;

        .notification-item {
          border-bottom: 1px solid #ddd;
          padding: 10px 0;

          .notification-item.read {
            background-color: transparent;
          }
          .sidebar-container
          .notification-item.unread {
            background-color: #f0f0f0;
          }
          &:last-child {
            border-bottom: none;
          }
          strong {
            display: block;
            font-size: 1rem;
          }
          p {
            font-size: 0.9rem;
            margin: 5px 0;
          }
          small {
            font-size: 0.8rem;
            color: gray;
          }
        }
      }
    }
  }
}

/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 576px) and (max-width: 991.98px) {
  .header-container {
    width: 100%;
    height: 65px;
    position: fixed;
    top: 0;
    z-index: 999;

    .top-header-section {
      width: 100%;
      height: 65px;
      background-color: #feca03;
      padding: 0 7.5%;
      justify-content: space-between;

      .top-left-container {
        height: 100%;
        width: 25%;
        justify-content: flex-start;
        // background-color: red;

        img {
          width: 90%;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .top-mid-container {
        display: none;
      }

      .top-right-container {
        height: 100%;
        width: 10%;
        justify-content: flex-end;
        gap: 1.5rem;
        // background-color: blue;

        .profile-icon-container {
          width: 40px;
          height: 40px;
          background-color: #fff;
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .menu-btn-container {
          width: max-content;
          height: 100%;

          .menu-btn-icon {
            font-size: 26px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  /* CSS rules for medium devices */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS rules for large devices */
}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /* CSS rules for extra large devices */
}
