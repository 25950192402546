.sidebar {
    width: 250px;
    height: 100vh;
    background-color: #3498db;
    color: white;
    position: fixed;
    display: flex;
    flex-direction: column;
    padding: 20px;
  
    .sidebar-title {
      font-size: 1.5rem;
      margin-bottom: 20px;
      text-align: center;
    }
  
    .menu {
      list-style: none;
      padding: 0;
  
      .menu-item {
        margin: 10px 0;
  
        a {
          text-decoration: none;
          color: white;
          font-size: 1rem;
          transition: color 0.3s;
  
          &:hover {
            color: #ecf0f1;
          }
        }
      }
    }
  }
  .content {
    margin-left: 250px; // Reserve space for the sidebar
    padding: 20px;
  }
  