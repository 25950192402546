@font-face {
  font-family: "Coffee Soda";
  src: url("./../font-coffee-soda/coffee-soda.ttf") format("truetype"),
    url("./../font-coffee-soda/coffee-soda.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.how-it-works-for-pet-parent-wrapper {
  width: 100%;
  height: max-content;
  padding-top: 65px;

  .first-section-wrapper {
    width: 100%;
    height: max-content;
    background-color: #ff744d;

    .info-container {
      width: 100%;
      height: 50rem;
      position: relative;

      .info-text-wrapper {
        width: 50%;
        height: 100%;
        padding-left: 10%;
        padding-top: 5rem;
        padding-right: 2.5rem;
        position: absolute;
        top: 0;
        left: 0;

        h1 {
          font-family: "Coffee Soda", sans-serif !important;
          color: #050066;
          font-size: 48px;
        }
      }

      .background-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        overflow: hidden;

        img {
          width: 100%;
        }
      }
    }

    .create-your-profile-container {
      width: 100%;
      height: calc(32rem + 8rem);
      padding: 4rem 10%;

      .info-container {
        width: 50%;
        height: 100%;
        flex-direction: column;

        h1 {
          font-family: "Coffee Soda", sans-serif !important;
          color: #fff;
          font-size: 48px;
        }

        p {
          color: #fff;
        }
      }

      .media-container {
        width: 50%;
        height: 100%;
        align-items: flex-start;

        img {
          width: 60%;
        }
      }
    }
  }

  .second-section-wrapper {
    width: 100%;
    height: 45rem;
    background-color: #3fa4ff;
    padding: 4rem 10%;

    .media-container {
      width: 40%;
      height: 100%;
      position: relative;

      img {
        width: 85%;
      }

      .book-now-button {
        position: absolute;
        bottom: 2.5rem;
        border: none;
        outline: none;
        background-color: #fff;
        font-size: 24px;
        font-weight: 700;
        padding: 0.75rem 7.5rem;
        border-radius: 20px;
      }
    }

    .info-container {
      width: 60%;
      height: max-content;
      text-align: start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #fff;
        font-size: 48px;
      }

      p {
        color: #fff;
        margin: 0;
      }

      h3 {
        color: #fff;
        font-size: 24px;
        margin-top: 1.5rem;
      }
    }
  }

  .third-section-wrapper {
    width: 100%;
    height: 45rem;
    background-color: #f99f0c;
    padding: 4rem 10%;

    .media-container {
      width: 40%;
      height: 100%;

      img {
        width: 100%;
      }
    }

    .info-container {
      width: 60%;
      height: max-content;
      text-align: start;
      padding-right: 2.5rem;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #fff;
        font-size: 48px;
      }

      p {
        color: #fff;
        margin: 0;
      }

      .sub-info-container {
        width: 100%;
        height: max-content;
        padding-top: 1.5rem;
        margin-top: 1.5rem;
        border-top: 1px solid #fff;

        .icon-container {
          width: 15%;
          height: 100%;

          img {
            width: 50%;
          }
        }

        .sub-info {
          width: 85%;
          height: max-content;

          h3 {
            font-size: 26px;
            color: #fff;
          }
        }
      }
    }
  }

  .fourth-section-wrapper {
    width: 100%;
    height: 45rem;
    background-color: #c7253f;
    padding: 4rem 10%;

    .media-container {
      width: 40%;
      height: 100%;

      img {
        width: 100%;
      }
    }

    .info-container {
      width: 60%;
      height: max-content;
      text-align: start;
      padding-left: 2.5rem;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #fff;
        font-size: 48px;
      }

      p {
        color: #fff;
        margin: 0;
      }

      .sub-info-container {
        width: 100%;
        height: max-content;
        padding-top: 1.5rem;
        margin-top: 1.5rem;
        border-top: 1px solid #fff;

        .icon-container {
          width: 15%;
          height: 100%;

          img {
            width: 50%;
          }
        }

        .sub-info {
          width: 85%;
          height: max-content;

          h3 {
            font-size: 26px;
            color: #fff;
          }
        }
      }
    }
  }

  .fifth-section-wrapper {
    width: 100%;
    height: 45rem;
    background-color: #c72591;
    padding: 4rem 10%;

    .media-container {
      width: 40%;
      height: 100%;

      img {
        width: 100%;
      }
    }

    .info-container {
      width: 60%;
      height: max-content;
      text-align: start;
      padding-right: 2.5rem;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #fff;
        font-size: 48px;
      }

      p {
        color: #fff;
        margin: 0;
      }

      .sub-info-container {
        width: 100%;
        height: max-content;
        padding-top: 1.5rem;
        margin-top: 1.5rem;
        border-top: 1px solid #fff;

        .icon-container {
          width: 15%;
          height: 100%;

          img {
            width: 50%;
          }
        }

        .sub-info {
          width: 85%;
          height: max-content;

          h3 {
            font-size: 26px;
            color: #fff;
          }
        }
      }
    }
  }

  .sixth-section-wrapper {
    width: 100%;
    height: max-content;
    flex-direction: column;
    padding: 10rem 10%;
    padding-bottom: 5rem;

    .heading-wrapper {
      width: 60%;
      text-align: center;
      margin-bottom: 7.5rem;
      padding: 0 2.5%;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #000;
        font-size: 48px;
        position: relative;

        img {
          position: absolute;
          width: 18%;
          right: 10%;
          top: -150%;
        }
      }
    }

    .contents-wrapper {
      width: 100%;
      gap: 15%;

      .left-side-contents-wrapper,
      .right-side-contents-wrapper {
        width: 50%;
        height: max-content;
        flex-direction: column;
        gap: 6rem;

        .icon-container {
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 65%;
          }
        }

        .info-container {
          width: 75%;

          h3 {
            font-size: 22px;
          }
        }
      }

      .right-side-contents-wrapper {
        width: 50%;
        height: max-content;
        flex-direction: column;
      }
    }
  }

  .seventh-section-wrapper {
    width: 100%;
    height: max-content;
    padding: 2.5rem 10%;
    margin-bottom: 5rem;

    .seventh-section-container {
      width: 100%;
      height: 354px;
      background-color: #ff744d;
      border-radius: 75px;
      padding: 5%;
      position: relative;

      .info-container {
        width: 50%;

        h1 {
          font-family: "Coffee Soda", sans-serif !important;
          color: #fff;
          font-size: 48px;
        }

        p {
          color: #fff;
        }
      }

      .media-container {
        width: 50%;

        img {
          width: 50%;
          position: absolute;
          bottom: -17%;
        }
      }
    }
  }
}
