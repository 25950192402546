* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Mob-sec {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .ovelay-up {
    width: 100%;
    height: 13%;
    background-color: #feca05;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
  }

  .mob-sec-button-container {
    width: 90%;
    height: 67%;
    border-radius: 1rem;
    // background-color: yellow;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;

    .woofily-logo-app-sec {
      // border: 2px solid #000;
      width: max-content;
      border-radius: 50%;
      margin-bottom: 1rem;
    }

    .app-download-info {
      text-align: center;

      h1 {
        font-size: 32px;
        font-weight: 600;
      }

      h2 {
        font-size: 28px;
        font-weight: 500;
        margin: 0;
        margin-top: 1rem;
      }

      p {
        font-size: 20px;
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;
      }
    }

    .app-download-button {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        width: max-content;
        height: 100%;

        img {
          width: 200px;
        }

        &:hover {
          img {
            transform: scale(1.04);
          }
        }

        &:nth-child(2) {
          img {
            width: 240px;
          }
        }
      }
    }

    .description {
      text-align: center;
      margin-top: 0.7rem;
    }
  }

  .dog-walker {
    width: 100%;
    height: 20%;
    // background-color: green;
  }
}
