* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.coffee-soda {
  font-family: "Coffee Soda", sans-serif !important;
  font-size: 48px;
}

.pet-care-tips-and-advice-wrapper {
  width: 100%;
  height: max-content;
  padding-top: 65px;

  .first-section-wrapper {
    width: 100%;
    height: 36rem;
    background-color: #c7253e;
    padding: 0rem 10%;

    .media-container {
      width: 40%;
      height: 100%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .info-container {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 9rem;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #fff;
        font-size: 48px;

        img {
          position: absolute;
          width: 22%;
          right: -20%;
          top: -128%;
        }
      }

      p {
        color: #fff;
        margin: 0;
      }

      h3 {
        color: #fff;
        font-size: 24px;
        margin-top: 1.5rem;
      }
    }
  }

  .second-section-wrapper {
    width: 100%;
    height: max-content;
    padding-top: 5rem;

    .top-section-wrapper {
      width: 100%;
      height: max-content;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #000;
        font-size: 48px;
      }
    }
    .tummy-info-container {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7.5rem;
      padding: 0 10%;

      .tummy-info {
        width: 33.33%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;

        img {
          width: 13%;
          margin-bottom: 1rem;
        }

        .tummy-sub-heading {
          font-size: 18px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }

  .third-section-wrapper {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    padding: 0 10%;
    padding-top: 7.5rem;

    .left-side-info-container {
      width: 50%;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #e89f0d;
        font-size: 46px;
      }

      .exercise-sub-info {
        width: 90%;
        height: max-content;
        background-color: #fcedcf;
        padding: 1.5rem;
        border-radius: 30px;

        h3 {
          font-size: 18px;
        }

        p {
          font-size: 14px;
          margin-bottom: 2rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .right-side-media-container {
      width: 50%;

      img {
        width: 90%;
      }
    }
  }

  .fourth-section-wrapper {
    width: 100%;
    padding: 0 10%;
    height: max-content;
    padding-top: 7.5rem;

    .top-regular-grooming-wrapper {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10rem;

      .left-media-wrapper {
        width: 50%;
        padding-right: 2.5rem;

        img {
          width: 100%;
        }
      }

      .right-info-wrapper {
        width: 50%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        h1 {
          color: #c7253e;
        }
      }
    }

    .bottom-regular-grooming-info-wrapper {
      width: 100%;
      height: max-content;
      gap: 2.5rem;

      .regular-grooming-info-card {
        width: calc(100% / 3);
        height: 275px;
        text-align: center;
        background-color: #f7d4d9;
        border-radius: 35px;
        padding: 1rem 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        padding-top: 85px;

        .icon-wrapper {
          background-color: #fff;
          width: 100px;
          height: 100px;
          position: absolute;
          top: -50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 1px 1px 5px #f0a8b4;

          img {
            width: 50%;
          }
        }

        h3 {
          font-family: "Coffee Soda", sans-serif !important;
          font-size: 22px;
        }
      }
    }
  }

  .fifth-section-wrapper {
    width: 100%;
    padding: 0 10%;
    height: max-content;
    padding-top: 7.5rem;

    .vet-visits-info-wrapper {
      width: 50%;
      padding-right: 3.5rem;

      h1,
      h2 {
        color: #c72591;
      }

      h2 {
        font-size: 24px;
        margin-bottom: 1.5rem;
      }

      h3 {
        font-size: 18px;
        margin-top: 2rem;
      }
    }

    .vet-visits-media-wrapper {
      width: 50%;

      img {
        width: 100%;
      }
    }
  }

  .sixth-section-wrapper {
    width: 100%;
    padding: 0 10%;
    height: max-content;
    padding-top: 7.5rem;

    h1 {
      width: 100%;
      text-align: center;
      line-height: 48px;
    }

    .sub-head {
      width: 100%;
      text-align: center;
      margin: 0;
      margin-top: 1rem;
    }

    .skills-wrapper {
      width: 100%;
      height: 300px;
      background-color: #ff744d;
      margin-top: 3rem;
      border-radius: 35px;
      padding: 1.5rem 5rem;
      text-align: center;
      color: #fff;
      gap: 2.5rem;

      .skill-card {
        // width: calc(100% / 3);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        &:last-child {
          .info-wrapper {
            .skill-content {
              border: none;
              padding: 0;
            }
          }
        }

        .icon-wrapper {
          width: 100px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 50%;
          }
        }

        .info-wrapper {
          width: 100%;

          .skill-heading {
            font-size: 18px;
            font-weight: 600;
          }

          .skill-content {
            width: 100%;
            font-size: 14px;
            border-right: 1px solid #fff;
            padding-right: 1rem;
            padding-left: 0;
          }
        }
      }
    }
  }

  .seventh-section-wrapper,
  .eighth-section-wrapper {
    width: 100%;
    padding: 0 10%;
    height: max-content;
    padding-top: 7.5rem;

    h1 {
      width: 100%;
      text-align: center;
    }

    .sub-head {
      width: 100%;
      text-align: center;
    }

    .activities-wrapper {
      width: 100%;
      gap: 3.5rem;
      align-items: flex-start;
      margin-top: 5rem;

      .activity-card {
        align-items: flex-start;
        gap: 1.5rem;

        .media-wrapper {
          width: 20%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
          }
        }

        .info-wrapper {
          width: 80%;

          .info-head {
            font-weight: 600;
          }

          .skill-content {
            font-size: 14px;
          }
        }
      }
    }
  }

  .ninth-section-wrapper {
    width: 100%;
    padding: 0 10%;
    height: max-content;
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;

    .more-advice-container {
      width: 100%;
      height: 350px;
      padding: 45px;
      background-color: #C72591;
      border-radius: 45px;

      .media-container {
        width: 50%;
        height: 100%;
      }

      .info-wrapper {
        width: 50%;
        height: max-content;
        color: #fff;
      }
    }
  }
}
