* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#editvaltext {
  &:hover {
    cursor: pointer;
  }
}

.main_content_container {
  position: absolute;
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  // border: 1px solid rgb(88, 88, 88);
  border-radius: 27px;
  padding: 0 1rem 0 0.5rem;
  box-shadow: 1px 1px 5px #0000003d;

  &:hover {
    cursor: pointer;
  }


  .arrow_up_down {
    transition: 0.4s ease-in-out;
  }

  input {
    width: calc(100% - 40px);
    height: 100%;
    border: none;
    outline: none;
    padding: 0.7rem 0.3rem 0.7rem 0.2rem;
    background-color: #ffffff;
    font-size: 14px;
    // font-weight: 600 !important;
    user-select: none;
    pointer-events: none;

    &:hover {
      cursor: pointer;
    }
  }

  p {
    font-size: 20px;
    margin-top: 15px;

    .location_icon {
      color: #feca03;
    }
  }
}

.dropdown_content_container {
  position: absolute;
  top: 85%;
  width: 125%;
  max-height: 70vh;
  background-color: #fff;
  border-radius: 15px;
  overflow-y: scroll;
//   padding: 1rem 0;
  border: 1px solid black;
  box-shadow: 1px 1px 10px #00000068;

  .current_location {
    width: 100%;
    height: max-content;
    // background-color: red;

    .address_input {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 0.6rem;

      input {
        width: 300px !important;
        padding: 0.2rem 0.5rem;
        outline: none;
        border: 1px solid black;
        border-radius: 5px;
      }

      button {
        padding: 0.2rem 1rem;
        margin-left: 1rem;
        border: 1px solid #feca03;
        border-radius: 5px;
        background: transparent;

        &:hover {
          background-color: #feca03;
          color: #fff;
        }
      }

      .current_location_btn {
        margin: 0;
        background-color: #feca03;

        &:hover {
          span {
            color: #000;
          }
        }

        span {
          font-size: 18px;
          margin-right: 0.5rem;
        }
      }
    }

    .gmap {
      width: 100%;
      height: max-content;
      margin-bottom: 1rem;
    }
  }

  .detect_current_location {
    width: 100%;
    height: max-content;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #feca03;
    
    &:hover {
        background-color: #fecc033b;
        cursor: pointer;
    }

    .icon_wrapper {
        width: max-content;
        height: 100%;
        font-size: 20px;
        padding: 0 0.5rem;
        display: flex;
        justify-content: center;
        margin-right: 0.5rem;
        padding-bottom: 1.1rem;
    }

    .content_wrapper {
        width: 100%;
        height: 100%;
        // background-color: green;

        p {
            margin: 0;
            font-size: 18px;
            margin-bottom: -0.4rem;
        }

        span {
            font-size: 14px;
            color: rgb(155, 155, 155);
            margin-top: -1rem;
        }
    }
  }

  .add_address_btn {
    .icon_wrapper {
        padding-bottom: 0;
        padding-top: 0.2rem;
        font-size: 16px;
    }
  }

//   .add_address_input_wrapper {
//     .address_input_autocomplete {
//         width: 500px;
//         height: max-content;
//         background-color: yellow;
    
//         input {
//             width: 100%;
//             background: transparent;
//         }
//       }
//   }

  .saved_addresses {
    width: 100%;
    height: max-content;
    // border: 1px solid red;

    .saved_address_heading {
      font-size: 20px;
      margin: 1rem 0 0.5rem 0.9rem;
    }

    .address {
      border-bottom: 1px solid #feca03;
      padding: 0.5rem 0.5rem 0.5rem 1.2rem;

      &:hover {
        background-color: #fecc033b;
        cursor: pointer;
      }

      h3 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
      }

      p {
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  }
}
