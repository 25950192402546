* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.our-story-section-wrapper {
  width: 100%;
  height: max-content;
  padding-top: 65px;

  .first-section-wrapper {
    width: 100%;
    height: 42rem;
    background-color: #3fa4ff;
    padding: 4rem 10% 4rem 5%;
    gap: 5rem;
    margin-bottom: 2.5rem;

    .media-container {
      width: 50%;
      height: 100%;
      position: relative;

      img {
        width: 100%;
      }
    }

    .info-container {
      width: 50%;
      height: max-content;
      text-align: start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #fff;
        font-size: 48px;
      }

      p {
        color: #fff;
        margin: 0;
      }

      h3 {
        color: #fff;
        font-size: 24px;
        margin-top: 1.5rem;
      }
    }
  }

  .second-section-wrapper {
    width: 100%;
    height: calc(60vh + 10rem);
    padding: 5rem 5% 5rem 10%;
    // background-color: orange;
    gap: 2.5rem;

    .info-container {
      width: 50%;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #c72591;
        font-size: 48px;

        img {
          position: absolute;
          width: 20%;
          right: -18%;
          top: -100%;
        }
      }

      p {
        color: #000;
      }
    }

    .media-container {
      width: 50%;
      height: 100%;
      align-items: flex-end;
      justify-content: flex-end;
      position: relative;
      // background-color: red;

      img {
        width: 100%;
      }

      .cta-btn {
        position: absolute;
        right: 15%;
        bottom: 10%;
        width: max-content;
        height: max-content;
        position: absolute;
        justify-content: flex-start;
        padding: 1rem 1.5rem;
        border-radius: 20px;
        box-shadow: 1px 1px 10px #ff744d;
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(5px);

        &:hover {
          cursor: pointer;
        }

        .cta-icon {
          padding: 0.75rem;
          background-color: #ff744d;
          color: #fff;
          border-radius: 15px;
          margin-right: 1rem;

          .icon {
            font-size: 28px;
          }
        }

        .cta-info {
          width: max-content;
          height: max-content;

          h3 {
            margin: 0;
            padding: 0;
            font-size: 22px;
          }

          p {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }

  .third-section-wrapper {
    width: 100%;
    height: calc(60vh + 5rem);
    padding: 2.5rem 10%;
    margin-bottom: 2.5rem;

    .info-container {
      width: 60%;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #9747ff;
        font-size: 48px;

        img {
          position: absolute;
          width: 22%;
          right: -22%;
          top: -115%;
        }
      }

      p {
        color: #000;
      }
    }

    .media-container {
      width: 40%;
      height: 100%;
      align-items: center;
      position: relative;

      img {
        width: 80%;
      }
    }
  }

  .fourth-section-wrapper {
    width: 100%;
    height: calc(60vh + 5rem);
    padding: 2.5rem 10%;

    .info-container {
      width: 50%;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #c72591;
        font-size: 48px;

        img {
          position: absolute;
          width: 30%;
          right: -25%;
          top: -120%;
        }
      }

      p {
        color: #000;
      }
    }

    .media-container {
      width: 50%;
      height: 100%;
      align-items: flex-start;
      justify-content: flex-end;
      position: relative;

      img {
        width: 85%;
      }

      .cta-btn {
        position: absolute;
        right: 15%;
        bottom: 10%;
        width: max-content;
        height: max-content;
        position: absolute;
        justify-content: flex-start;
        padding: 1rem 1.5rem;
        border-radius: 20px;
        box-shadow: 1px 1px 10px #ff744d;
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(5px);

        &:hover {
          cursor: pointer;
        }

        .cta-icon {
          padding: 0.75rem;
          background-color: #ff744d;
          color: #fff;
          border-radius: 15px;
          margin-right: 1rem;

          .icon {
            font-size: 28px;
          }
        }

        .cta-info {
          width: max-content;
          height: max-content;

          h3 {
            margin: 0;
            padding: 0;
            font-size: 22px;
          }

          p {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
}
