* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.all-hosts-wrapper {
  width: 100%;
  height: max-content;
  padding: 90px 5%;

  .all-hosts-heading {
    font-size: 28px;
    margin: 1rem 0 2rem 0;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 0.2rem 0.5rem;
  }

  .all-hosts {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 40px 10px;
  }
}