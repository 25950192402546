* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  color: #000;
  font-size: 48px;
  position: relative;

  img {
    position: absolute;
    width: 18%;
    right: 10%;
    top: -150%;
  }
}

.host-dedicated-support-wrapper {
  width: 100%;
  height: max-content;
  background-color: #f8f8f8;
  padding-top: 65px;

  .first-section-wrapper {
    width: 100%;
    height: 36rem;
    background-color: #c7253e;
    padding: 0rem 10%;

    .media-container {
      width: 40%;
      height: 100%;
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .info-container {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 9rem;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #fff;
        font-size: 48px;

        img {
          position: absolute;
          width: 22%;
          right: -20%;
          top: -128%;
        }
      }

      p {
        color: #fff;
        margin: 0;
      }

      h3 {
        color: #fff;
        font-size: 24px;
        margin-top: 1.5rem;
      }
    }
  }

  .second-section-wrapper {
    width: 100%;
    height: calc(60vh + 5rem);
    padding: 2.5rem 10%;

    .info-container {
      width: 60%;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #9747ff;
        font-size: 48px;

        img {
          position: absolute;
          width: 22%;
          right: -22%;
          top: -115%;
        }
      }

      p {
        color: #000;
      }
    }

    .media-container {
      width: 40%;
      height: 100%;
      align-items: flex-end;
      position: relative;

      img {
        width: 55%;
      }
    }
  }

  .third-section-wrapper {
    width: 100%;
    height: max-content;
    flex-direction: column;
    padding: 2.5rem 10%;

    .central-heading-container {
      width: 100%;
      height: max-content;
      text-align: center;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #c7253f;

        img {
          width: 10%;
          right: -4%;
          top: -150%;
        }
      }
    }

    .needs-info-cards-wrapper {
      width: 100%;
      height: max-content;
      align-items: flex-start;
      gap: 1.5rem;
      margin-top: 3rem;

      .left-cards-container,
      .middle-cards-container,
      .right-cards-container {
        width: calc(100% / 3);

        .needs-card {
          width: 100%;
          height: max-content;
          border-radius: 45px;
          padding: 2.5rem;
          margin-bottom: 1.5rem;
          color: #fff;

          p {
            font-size: 14px;
          }

          .card-heading {
            justify-content: flex-start;
            gap: 1.5rem;
            margin-bottom: 1rem;

            img {
              width: 50px;
            }

            h3 {
              font-size: 20px;
              margin: 0;
              padding: 0;
            }
          }
        }

        .card-one {
          background-color: #9747ff;
        }

        .card-two {
          background-color: #c72591;
        }

        .card-three {
          background-color: #3399ff;
        }

        .card-four {
          background-color: #c7253e;
          position: relative;

          .hanging-cat {
            position: absolute;
            top: -53%;
            left: 50%;
            transform: translate(-50%);
          }
        }

        .card-five {
          background-color: #bf00e5;
        }

        .card-six {
          background-color: #e85c0d;
        }

        .card-seven {
          background-color: #e89f0d;
        }
      }

      .middle-cards-container {
        padding-top: 11rem;
      }
    }
  }

  .fourth-section-wrapper {
    width: 100%;
    height: max-content;
    flex-direction: column;
    padding: 5rem 10%;
    position: relative;

    .central-heading-container {
      width: 100%;
      height: max-content;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #ff744d;
        width: max-content;

        img {
          width: 13%;
          right: -10%;
          top: -105%;
        }
      }
    }

    .services-wrapper {
      width: 100%;
      align-items: flex-start;
      height: max-content;
      gap: 5rem;
      margin-top: 2.5rem;

      .service-container {
        width: calc(100% / 5);
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .service-image {
          width: 65%;
          height: 7.5rem;
          background-color: #ffd7cc;
          border-radius: 25px;
        }

        .service {
          text-align: center;
          margin-top: 1.5rem;

          h3 {
            font-size: 20px;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  .fifth-section-wrapper {
    width: 100%;
    height: max-content;
    flex-direction: column;
    padding: 5rem 10%;
    position: relative;

    .central-heading-container {
      width: 100%;
      height: max-content;
      text-align: start;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #bf00e5;
        width: max-content;

        img {
          width: 15%;
          right: -10.5%;
          top: -120%;
        }
      }
    }

    .testimonial-wrapper {
      width: 100%;
      height: max-content;
      align-items: flex-start;
      gap: 5rem;

      .testimonial {
        width: calc(100% / 3);
        background-color: #f7ccff;
        height: calc(300px + 5rem);
        padding: 2.5rem;
        border-radius: 40px;
        margin-top: 2.5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &:nth-child(2) {
          .profile-img {
            img {
              width: 125% !important;
            }
          }
        }

        .profile-wrapper {
          width: 100%;
          height: 100px;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 1rem;

          .profile-img {
            width: 25%;
            overflow: hidden;
            margin-right: 1rem;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;

            img {
              width: 100%;
              object-fit: contain;
            }
          }

          .profile-name {
            width: 75%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            h3 {
              font-size: 20px;
              margin: 0;
              padding: 0;
            }

            p {
              font-size: 14px;
              margin: 0;
              padding: 0;
            }
          }
        }

        .review-wrapper {
          width: 100%;
        }
      }
    }
  }

  .sixth-section-wrapper {
    width: 100%;
    height: max-content;
    padding: 2.5rem 10%;
    margin-bottom: 5rem;

    .seventh-section-container {
      width: 100%;
      height: 400px;
      background-color: #ff744d;
      border-radius: 75px;
      padding: 5%;
      position: relative;

      .info-container {
        width: 70%;

        h1 {
          font-family: "Coffee Soda", sans-serif !important;
          color: #fff;
          font-size: 48px;
        }

        p {
          color: #fff;
        }

        .bold {
          font-weight: 600;
        }
      }

      .media-container {
        width: 30%;

        img {
          width: 65%;
          position: absolute;
          bottom: -17%;
          right: -10%;
        }
      }
    }
  }
}
