* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mybooking {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  // background-color: orange;

  .mybooking-heading {
    width: 85%;
    height: auto;

    h4 {
      font-size: 28px;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      padding: 0.5rem;
    }
  }
}

.checkout_flex2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h5 {
    font-family: "RecoletaSemiBold";
  }

  .best-host-data {
    width: 85%;
    height: max-content;
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    // border: 1px solid black;
    gap: 1rem;
    padding-bottom: 5rem;

    .best-host-inner {
      width: 45%;
      height: 180px;

      display: flex;
      //   border: 1px solid red;
      background-color: white;
      justify-content: space-between;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      border-radius: 15px;

      .best_host_flex1 {
        width: 35%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 90%;
          height: 90%;
          object-fit: cover;
          border-radius: 15px;
        }
      }

      .best_host_flex2 {
        width: 60%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 4px;
        padding: 0.7rem 0;

        .role {
          color: black;
        }

        .timings {
          width: 100%;
          height: max-content;
          display: flex;

          .start,
          .end {
            width: 50%;

            p {
              font-size: 12px;
              margin: 0;
              padding: 0;
              color: #000;
            }
          }

          // p {
          //   margin: 0;
          //   padding: 0;
          //   display: inline;
          //   margin-right: 0.5rem;

          //   span {
          //     color: gray;
          //   }
          // }
        }

        .payment_button {
          width: 100%;
          height: max-content;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 1.5rem;
          margin-top: 0.5rem;
          // background-color: green;

          .pay_btn {
            width: 50%;
            background-color: #feca05;
            color: #000;
            text-align: center;
            padding: 0.3rem 0;
            border-radius: 27px;
            text-decoration: none;
            font-weight: 500;
          }
          .order_btn {
            width: 50%;
            background-color: #000;
            color: #fff;
            text-align: center;
            padding: 0.3rem 0;
            border-radius: 27px;
            text-decoration: none;
            font-weight: 500;
          }
          .paid {
            margin: 0;
          }
        }

        h6 {
          padding: 0;
          margin: 0;

          &:nth-child(3) {
            font-size: 14px;
            font-family: "RecoletaSemiBold";
            color: black;
          }

          // &:nth-child(4) {
          //     font-size: 14px;
          //     font-family: "RecoletaSemiBold";
          //     color: green;

          // }
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .mybooking {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .mybooking-heading {
      width: 80%;
      height: auto;

      h4 {
        font-family: "RecoletaSemiBold";
      }
    }
  }

  .checkout_flex2 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h5 {
      font-family: "RecoletaSemiBold";
    }

    .best-host-data {
      width: 90%;
      height: max-content;
      display: flex !important;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      // border: 1px solid black;
      gap: 1rem;
      padding-bottom: 5rem;

      .best-host-inner {
        width: 100%;
        height: 180px;

        display: flex;
        //   border: 1px solid red;
        background-color: white;
        justify-content: space-between;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
        border-radius: 15px;

        .best_host_flex1 {
          width: 35%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 90%;
            height: 90%;
            object-fit: cover;
            border-radius: 15px;
          }
        }

        .best_host_flex2 {
          width: 60%;
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          gap: 4px;

          h6 {
            font-family: "RecoletaRegular";
            font-size: 12px;
          }

          .role {
            color: black;
          }

          h6 {
            padding: 0;
            margin: 0;

            &:nth-child(3) {
              font-size: 12px;
              font-family: "RecoletaSemiBold";
              color: black;
            }

            // &:nth-child(4) {
            //     font-size: 14px;
            //     font-family: "RecoletaSemiBold";
            //     color: green;

            // }
          }
        }
      }
    }
  }
}

