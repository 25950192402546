@font-face {
  font-family: "Coffee Soda";
  src: url("./../../modules/hosting-module/pages/footer-pages/font-coffee-soda/coffee-soda.ttf")
      format("truetype"),
    url("./../../modules/hosting-module/pages/footer-pages/font-coffee-soda/coffee-soda.otf")
      format("opentype");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header-one {
  font-family: "Coffee Soda", sans-serif !important;
}

.register-container-wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: url("../../../public/assets/images/auth/auth-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 5%;

  .form-container-wrapper {
    width: 60%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
    padding-left: 15%;
    padding-right: 20%;

    .login-form-container {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 1.5rem;
      margin-bottom: 1rem;

      input,
      button {
        width: 100%;
        margin-bottom: 1rem;
        border: none;
        outline: none;
        padding: 1rem;
        border-radius: 20px;

        &:nth-child(2) {
          // display: none;
        }

        &:disabled {
          // background-color: #d2d2d2;
          color: #d2d2d2;

          &::placeholder {
            color: #b2b2b2;
          }
        }

        &:focus {
          border: none;
          outline: none;
        }
      }

      button {
        background-color: #feca2a;
        font-size: 20px;
        padding: 0.75rem;
      }
    }

    .sign-up-navigation {
      width: 100%;
      text-align: center;
    }

    .privacy-policy-acceptance {
      width: 100%;
      text-align: center;
      margin-top: 5rem;
    }
  }

  .content-container-wrapper {
    width: 40%;
    height: 100%;
    // background-color: white;

    .main-img-wrapper {
      width: 90%;
      height: max-content;
      // background-color: orange;
      position: relative;

      img {
        width: 100%;
      }
    }

    .paw-overlay {
      position: absolute;
      top: -10px;
      right: -10px;
      // background-color: #151515;
      border-radius: 50%;
      width: 80px;
      height: 80px;

      img {
        width: 100%;
      }
    }

    .content-carousel-wrapper {
      width: 80%;
      min-height: 200px;
      border-radius: 27px;
      padding: 20px 30px;
      position: absolute;
      bottom: 7.5%;
      left: -20%;
      background-color: #fff;

      h1 {
        font-size: 22px;
      }

      p {
        font-size: 16px;
      }
    }
  }
}
