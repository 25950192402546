.petform {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4rem;
  padding-top: 40px;
  // border: 1px solid pink;

  .petform_root {
      width: 78%;
      height: 100%;
      // background-color: orange;
      // border: 1px solid red;

      .petform_root_btn_rev {
          // margin-top: 2rem;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 10px;
          // background-color: red;
          padding-bottom: 2.5rem;

          button {
              width: 25%;
              padding: 8px 0px 8px 0px;
              border: none;
              outline: none;
              border-radius: 39px;
              font-family: "RecoletaRegular";
              background-color: #FECA03;
              color: white;
              cursor: pointer;
          }
      }
  }
}

button {
    cursor: pointer !important;
}