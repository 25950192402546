.host_bar {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  padding: 0 1rem;
  padding-top: 110px;

  .host_container {
    width: 100%;
    height: max-content;
    display: flex;
    gap: 2rem;
    align-items: flex-start;
    justify-content: center;

    .host_sidebar {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      // margin-top: 1rem;
      margin-top: 2rem;

      .host_sidebar_inner {
        background-color: white;
        border-radius: 18px;
        width: 100%;
        display: flex;
        padding: 1rem;
        justify-content: center;
        // border: 1px solid green;
        box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);
        // background-color: orange;

        ul {
          width: 100%;
          height: auto;
          list-style-type: none;
          padding: 0;
          border-radius: 12px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 15px;

          li {
            height: 40px;
            width: 100%;
            display: flex;
            //border: 1px solid blue;
            align-items: center;
            justify-content: center;
            margin: 0;
            // padding: 1rem;
            list-style-type: none;

            a {
              width: 90%;
              height: 40px;
              // border: 1px solid green;
              // padding: 4px 4px 4px 4px;
              border-radius: 12px;
              background: #fff8e0;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-left: 10px;
              text-decoration: none;
              gap: 20px;
              color: black;

              h5 {
                font-family: "RecoletaRegular";
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    .host_outlet {
      width: 70%;
      height: auto;
      // border: 1px solid green;
      display: flex;
      // justify-content: center;
      align-items: flex-start;
      // background-color: green;
      padding-bottom: 1rem;
      padding-left: 2.5rem;

      .host_outlet_innr {
        width: 100%;
        height: auto;
        margin-top: 2rem;
        // background-color: green;
      }
    }
  }
}
