@font-face {
  font-family: "Coffee Soda";
  src: url("./../font-coffee-soda/coffee-soda.ttf") format("truetype"),
    url("./../font-coffee-soda/coffee-soda.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  color: #000;
  font-size: 48px;
  position: relative;

  img {
    position: absolute;
    width: 18%;
    right: 10%;
    top: -150%;
  }
}

.become-a-pet-host-with-woofily-wrapper {
  width: 100%;
  height: max-content;
  background-color: #f8f8f8;
  padding-top: 65px;

  .first-section-wrapper {
    width: 100%;
    height: calc(60vh + 5rem);
    padding: 2.5rem 10%;

    .info-container {
      width: 40%;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #ff744d;
        font-size: 48px;

        img {
          position: absolute;
          width: 25%;
          right: -25%;
          top: -115%;
        }
      }

      p {
        color: #000;
      }
    }

    .media-container {
      width: 60%;
      height: 100%;
      align-items: flex-start;
      justify-content: flex-end;
      position: relative;

      img {
        width: 85%;
      }

      .cta-btn {
        position: absolute;
        right: 15%;
        bottom: 10%;
        width: max-content;
        height: max-content;
        position: absolute;
        justify-content: flex-start;
        padding: 1rem 1.5rem;
        border-radius: 20px;
        box-shadow: 1px 1px 10px #ff744d;
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(5px);

        &:hover {
          cursor: pointer;
        }

        .cta-icon {
          padding: 0.75rem;
          background-color: #ff744d;
          color: #fff;
          border-radius: 15px;
          margin-right: 1rem;

          .icon {
            font-size: 28px;
          }
        }

        .cta-info {
          width: max-content;
          height: max-content;

          h3 {
            margin: 0;
            padding: 0;
            font-size: 22px;
          }

          p {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }

  .second-section-wrapper {
    width: 100%;
    height: calc(60vh + 5rem);
    padding: 2.5rem 10%;

    .info-container {
      width: 60%;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #9747ff;
        font-size: 48px;

        img {
          position: absolute;
          width: 22%;
          right: -22%;
          top: -115%;
        }
      }

      p {
        color: #000;
      }
    }

    .media-container {
      width: 40%;
      height: 100%;
      align-items: flex-end;
      position: relative;

      img {
        width: 55%;
      }
    }
  }

  .third-section-wrapper {
    width: 100%;
    height: max-content;
    flex-direction: column;
    padding: 2.5rem 10%;

    .central-heading-container {
      width: 100%;
      height: max-content;
      text-align: center;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #c7253f;

        img {
          width: 10%;
          right: 13.5%;
          top: -150%;
        }
      }
    }

    .needs-info-cards-wrapper {
      width: 100%;
      height: max-content;
      align-items: flex-start;
      gap: 1.5rem;
      margin-top: 3rem;

      .left-cards-container,
      .middle-cards-container,
      .right-cards-container {
        width: calc(100% / 3);

        .needs-card {
          width: 100%;
          height: 300px;
          border-radius: 45px;
          padding: 2.5rem;
          margin-bottom: 1.5rem;
          color: #fff;

          .card-heading {
            justify-content: flex-start;
            gap: 1.5rem;
            margin-bottom: 1rem;

            img {
              width: 50px;
            }

            h3 {
              font-size: 22px;
              margin: 0;
              padding: 0;
            }
          }
        }

        .card-one {
          background-color: #9747ff;
        }

        .card-two {
          background-color: #c72591;
        }

        .card-three {
          background-color: #3399ff;
        }

        .card-four {
          background-color: #c7253e;
          position: relative;

          .hanging-cat {
            position: absolute;
            top: -59%;
            left: 50%;
            transform: translate(-50%);
          }
        }

        .card-five {
          background-color: #bf00e5;
        }

        .card-six {
          background-color: #e85c0d;
        }

        .card-seven {
          background-color: #e89f0d;
        }
      }

      .middle-cards-container {
        padding-top: 11rem;
      }
    }
  }

  .fourth-section-wrapper {
    width: 100%;
    height: calc(60vh + 0rem);
    padding: 0rem 10%;

    .info-container {
      width: 50%;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #c72591;
        font-size: 48px;

        img {
          position: absolute;
          width: 25%;
          right: -20%;
          top: -125%;
        }
      }

      p {
        color: #000;
      }
    }

    .media-container {
      width: 50%;
      height: 100%;
      align-items: flex-start;
      justify-content: flex-end;
      position: relative;

      img {
        width: 85%;
      }

      .cta-btn {
        position: absolute;
        right: 15%;
        bottom: 10%;
        width: max-content;
        height: max-content;
        position: absolute;
        justify-content: flex-start;
        padding: 1rem 1.5rem;
        border-radius: 20px;
        box-shadow: 1px 1px 10px #ff744d;
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(5px);

        &:hover {
          cursor: pointer;
        }

        .cta-icon {
          padding: 0.75rem;
          background-color: #ff744d;
          color: #fff;
          border-radius: 15px;
          margin-right: 1rem;

          .icon {
            font-size: 28px;
          }
        }

        .cta-info {
          width: max-content;
          height: max-content;

          h3 {
            margin: 0;
            padding: 0;
            font-size: 22px;
          }

          p {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }

  .fifth-section-wrapper {
    width: 100%;
    height: max-content;
    flex-direction: column;
    padding: 2.5rem 10%;

    .central-heading-container {
      width: 100%;
      height: max-content;
      text-align: center;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #e89f0d;

        img {
          width: 10%;
          right: 13.5%;
          top: -150%;
        }
      }
    }

    .needs-info-cards-wrapper {
      width: 100%;
      height: max-content;
      align-items: flex-start;
      gap: 2.5rem;
      margin-top: 3rem;

      .left-cards-container,
      .right-cards-container {
        width: calc(100% / 2);

        .needs-card {
          width: 100%;
          height: 200px;
          border-radius: 40px;
          padding: 2.5rem;
          margin-bottom: 2.5rem;
          color: #000;
          background-color: #fcedcf;

          .card-heading {
            justify-content: flex-start;
            gap: 1.5rem;
            margin-left: 1rem;

            h3 {
              font-size: 22px;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .sixth-section-wrapper {
    width: 100%;
    height: max-content;
    flex-direction: column;
    padding: 5rem 10%;
    position: relative;

    .central-heading-container {
      width: 100%;
      height: max-content;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #3399ff;
        width: max-content;

        img {
          width: 25%;
          right: -18%;
          top: -130%;
        }
      }
    }

    .start-info-cards-wrapper {
      width: 100%;
      height: max-content;
      align-items: flex-start;
      gap: 5rem;
      margin-top: 3rem;
      position: relative;

      .mobile-card {
        width: calc(100% / 3);
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        h3 {
          text-align: center;
          font-size: 22px;
          margin-top: 1.5rem;
        }

        p {
          text-align: center;
        }
      }

      .straight-dashed-arrow {
        position: absolute;
        left: calc(100% / 4);
        top: 30%;

        &:nth-child(2) {
          position: absolute;
          left: calc(60%);
          top: 30%;
        }
      }
    }

    .curved-dashed-arrow {
      width: 30%;
      position: absolute;
      right: 20%;
      bottom: 33%;
    }

    .start-hosting-card {
      width: 100%;
      height: max-content;
      flex-direction: column;
      margin-top: 12rem;

      img {
        width: 60%;
      }
    }
  }

  .seventh-section-wrapper {
    width: 100%;
    height: max-content;
    flex-direction: column;
    padding: 5rem 10%;
    position: relative;

    .central-heading-container {
      width: 100%;
      height: max-content;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #ff744d;
        width: max-content;

        img {
          width: 20%;
          right: -15%;
          top: -130%;
        }
      }
    }

    .services-wrapper {
      width: 100%;
      align-items: flex-start;
      height: max-content;
      gap: 5rem;
      margin-top: 2.5rem;

      .service-container {
        width: calc(100% / 5);
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .service-image {
          width: 65%;
          height: 7.5rem;
          background-color: #ffd7cc;
          border-radius: 25px;
        }

        .service {
          text-align: center;
          margin-top: 1.5rem;

          h3 {
            font-size: 20px;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  .eigth-section-wrapper {
    width: 100%;
    height: max-content;
    flex-direction: column;
    padding: 5rem 10%;
    position: relative;

    .central-heading-container {
      width: 100%;
      height: max-content;
      text-align: start;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #BF00E5;
        width: max-content;

        img {
          width: 25%;
          right: -17.5%;
          top: -120%;
        }
      }
    }
    
    .testimonial-wrapper {
      width: 100%;
      height: max-content;
      align-items: flex-start;
      gap: 5rem;

      .testimonial {
        width: calc(100% / 3);
        background-color: #F7CCFF;
        height: calc(300px + 5rem);
        padding: 2.5rem;
        border-radius: 40px;
        margin-top: 2.5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &:nth-child(2) {
          .profile-img {
            img {
              width: 125% !important;
            }
          }
        }

        .profile-wrapper {
          width: 100%;
          height: 100px;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 1rem;

          .profile-img {
            width: 25%;
            overflow: hidden;
            margin-right: 1rem;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;

            img {
              width: 100%;
              object-fit: contain;
            }
          }

          .profile-name {
            width: 75%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            h3 {
              font-size: 20px;
              margin: 0;
              padding: 0;
            }

            p {
              font-size: 14px;
              margin: 0;
              padding: 0;
            }
          }
        }

        .review-wrapper {
          width: 100%;
        }
      }
    }
  }

  .ninth-section-wrapper {
    width: 100%;
    height: max-content;
    padding: 2.5rem 10%;
    margin-bottom: 5rem;

    .seventh-section-container {
      width: 100%;
      height: 454px;
      background-color: #ff744d;
      border-radius: 75px;
      padding: 0 5%;
      padding-right: 0;

      .info-container {
        width: 50%;

        h1 {
          font-family: "Coffee Soda", sans-serif !important;
          color: #fff;
          font-size: 68px;

          img {
            position: absolute;
            width: 28%;
            right: -10%;
            top: -100%;
          }
        }

        p {
          color: #fff;
        }
      }

      .media-container {
        width: 50%;
        padding: 0;

        img {
          width: 100%;
        }
      }
    }
  }
}