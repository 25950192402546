/* hostDocumentForm.scss */

.host-document-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensures full-page height */
    .content {
      flex: 1; /* Allows the content section to grow */
      padding: 82px;
      background-color: #f9f9f9; /* Light background for form area */
    }
  
    .tabs-container {
      display: flex;
      justify-content: center;
      margin-top: 26px;
    }
   .verified-message{
     margin-top: 151px;}

    .form {
      max-width: 1000px; /* Limits the form width */
      margin: 0 auto; /* Centers the form */
      background-color: #ffffff; /* White background for the form */
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    button {
      margin-top: 10px;
      margin-right: 10px;
      background-color: black; 
      color: #fff; 
      border: none; 
      padding: 10px 20px; 
      border-radius: 5px; 
      font-size: 16px; 
      cursor: pointer; 
      transition: background-color 0.3s ease; 
    }
    button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    }
    button:active {
    background-color: #003f7f; /* Even darker blue when active */
    }
    button:disabled {
    background-color: #cccccc; /* Gray color for disabled buttons */
    cursor: not-allowed; /* Show not-allowed cursor */
    }
  }
  .tabs-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  
    .tab-button {
      padding: 10px 20px;
      margin: 0 10px;
      background-color: #e0e0e0;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s;
      &.active {
        background-color: black; 
        color: #ffffff;
      }
      &:hover {
        background-color: #0056b3;
      }
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  
    label {
      display: flex;
      flex-direction: column;
      font-size: 18px;
    }
  
    input[type="text"],
    input[type="file"] {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  
    
  }
 

  

