* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #f8f8f8;
}

.profile_edit_complete {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3.5rem;
  padding-top: 2.5rem;

  .profile_edit_root_complete {
    width: 80% !important;
    // border: 1px solid red;

    padding: 1rem 0px;

    form {
      display: flex;
      flex-direction: column;
      gap: 0px;
      margin-top: 20px;
      // border: 1px solid black;

      .edit_form_data {
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 80px !important;
        // border: 1px solid blue;

        &:nth-child(6) {
          width: 100%;

          .lab_inp {
            width: 100% !important;

            textarea {
              width: 100% !important;
              border-radius: 37px;
              resize: none;
            }
          }
        }

        .lab_inp {
          width: 50% !important;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 3px !important;
          padding-top: 10px;
          // border: 1px solid red;

          .time-picker-wrapper {
            width: 100%;

            input {
              width: 100% !important;
            }
          }

          input {
            width: 100% !important;
          }

          &:nth-child(8) {
            background-color: green;
          }

          .profile_edit_label {
            label {
              margin-left: 20px;
              padding-top: 10px;
              font-size: 16px !important;
              font-family: "RecoletaSemiBold";
            }
          }
          .order_summary_para {
            h6 {
              font-family: "RecoletaRegular";
              margin-left: 20px;
            }
          }
          .locality {
            display: flex;
            width: 100%;
            // border: 1px solid red;
            gap: 1rem;
            input {
              width: 100%;
              // border: 1px solid red;
            }
          }
          textarea {
            width: 100%;
            border-radius: 12px;
            padding: 12px 30px 12px 30px;
            border: none;
            outline: none;
            font-family: "RecoletaRegular";
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
          }

          input {
            width: 100%;
            border-radius: 38px;
            padding: 12px 30px 12px 30px;
            border: none;
            outline: none;
            font-family: "RecoletaRegular";
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
          }

          .datepicker, 
          .time-picker {
            width: 100%;
            border-radius: 38px;
            // padding: 12px 30px 12px 30px;
            border: none;
            outline: none;
            font-family: "RecoletaRegular";
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
            // background-color: #a51919;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 25px;

            &:hover {
              cursor: pointer;
            }

            input {
              background: transparent;
              border: none;
              box-shadow: none;
              // background-color: green;
            }
          }
        }

        .booking-type-inputs {
          width: 50% !important;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 3px !important;
          padding-top: 10px;

          .profile_edit_label {
            label {
              margin-left: 20px;
              padding-top: 0px;
              font-size: 16px !important;
            }
          }

          .radio-wrappers {
            width: 100%;
            height: max-content;
            justify-content: space-between;

            label {
              width: 45%;
              text-align: center;
              border-radius: 38px;
              padding: 12px 30px 12px 30px;
              border: none;
              outline: none;
              box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
              cursor: pointer;
            }

            input {
              display: none;

              &:checked + label {
                background-color: #feca05;
              }
            }
          }
        }

        .threewidth {
          width: 30%;
        }

        .fullwidth {
          width: 100% !important;
        }
      }
    }

    .edit_submit_profile_button {
      margin-top: 10px;
      margin-bottom: 10px;

      button {
        background-color: #feca03;
        border: none;
        outline: none;
        border-radius: 38px;
        padding: 10px 6rem 10px 6rem;
        font-family: "RecoletaRegular";
      }
    }
  }
}