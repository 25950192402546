
.our-story-section-wrapper {
    width: 100%;
    height: max-content;
    padding-top: 65px;
  
    .first-section-wrapper {
      width: 100%;
      height: 42rem;
      background-color: white;
      padding: 4rem 10% 4rem 5%;
      gap: 5rem;
      margin-bottom: 2.5rem;
  
      .media-container {
        width: 50%;
        height: 100%;
        position: relative;
  
        img {
          width: 100%;
        }
      }
  
      .info-container {
        width: 50%;
        height: max-content;
        text-align: start;
  
        h1 {
          font-family: "Coffee Soda", sans-serif !important;
          // color: #fff;
          color: #000;
          font-size: 48px;
        }
  
        p {
          // color: #fff;
          color: #000;
          margin: 0;
        }
  
        h3 {
          color: #fff;
          font-size: 24px;
          margin-top: 1.5rem;
        }
      }
    }
  
    .contact-details-wrapper {
        background: #f8f9fa;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        text-align: center;
        width: 100%;
        max-width: 500px;
        margin: 2rem auto;
      
        .contact-heading {
          font-family: "Coffee Soda", sans-serif !important;
          color: #333;
          font-size: 2rem;
          margin-bottom: 1.5rem;
        }
      
        .contact-detail {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 1rem;
          gap: 0.5rem;
      
          .contact-icon {
            color: #007bff;
            font-size: 1.5rem;
          }
      
          .contact-info {
            font-size: 1rem;
            color: #555;
          }
        }
      }
      
  }