* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .host-profile-sections-wrapper {
    max-width: 100%;
    height: max-content;
    padding-top: 110px;
    padding: 65px 5% 5rem 5%;
  
    .first-section-cover-pic-wrapper {
      width: 100%;
      height: max-content;
      padding: 2rem 0;
      // background-color: orange;
      position: relative;
  
      .host-cover-pic-container {
        width: 100%;
        height: 24vw;
        background-color: #fff;
        border-radius: 3vw;
        overflow: hidden;
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
  
      .host-profile-pic-container {
        width: 11vw;
        height: 11vw;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        bottom: 2rem;
        left: 4vw;
        transform: translate(0, 35%);
        overflow: hidden;
        border: 2px solid #fff;
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  
    .second-section-content-sections-wrapper {
      width: 100%;
      height: max-content;
      align-items: flex-start;
      gap: 2.5rem;
  
      .host-details-wrapper {
        width: 60%;
        height: max-content;
  
        .host-name-location-container {
          width: 100%;
          height: max-content;
          padding-top: 3rem;
  
          .host-name {
            font-size: 28px;
          }
  
          .host-id {
            font-size: 16px;
            color: gray;
          }
  
          .host-location {
            font-size: 16px;
          }
        }
  
        .info-review-photo-count-container {
          width: 100%;
          height: max-content;
          padding: 0.5rem 0.3rem;
          justify-content: space-between;
          border-top: 1px solid black;
          border-bottom: 1px solid black;
          margin-top: 1.5rem;
  
          .info-container,
          .review-container,
          .photo-container {
            width: max-content;
            height: 100%;
            flex-direction: column;
            align-items: center;
  
            .info-heading {
              font-size: 18px;
              margin-bottom: 0.3rem;
              font-weight: 500;
            }
  
            .info-count {
              font-size: 18px;
              text-align: center;
            }
          }
        }
  
        .about-host-container {
          width: 100%;
          height: max-content;
          padding: 1rem 0 0 0;
          // border-bottom: 1px solid #000;
          padding-bottom: 0.8rem;
  
          .about-heading {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0.25rem;
          }
  
          .about-text {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 0.75rem;
          }
  
          .host-gender,
          .host-age,
          .host-profession {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 0.25rem;
  
            span {
              font-weight: 500;
            }
          }
        }
  
        .host-photos-wrapper {
          width: 100%;
          height: max-content;
          padding-top: 1rem;
          padding-bottom: 1rem;
  
          .host-post-pics-heading {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0.75rem;
            border-bottom: 1px solid black;
            border-top: 1px solid black;
            padding: 0.75rem 0;
          }
  
          .host-photos-container {
            width: 100%;
            height: max-content;
            flex-wrap: wrap;
            gap: 0.3rem;
            justify-content: space-between;
  
            .host-pic {
              width: 175px;
              height: 175px;
              border-radius: 50%;
              border: 1px solid black;
              overflow: hidden;
              // background-color: orange;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              object-fit: cover;
  
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
  
          .see-more-pics-button-wrapper {
            width: 100%;
            height: max-content;
            margin-top: 1rem;
  
            .see-more-pics-button {
              background-color: #feca03;
              border: none;
              outline: none;
              padding: 0.5rem 2.5rem;
              font-size: 16px;
              font-weight: 500;
              border-radius: 27px;
              box-shadow: 0.1px 0.1px 5px #feca03, -0.1px -0.1px 5px #feca03;
            }
          }
        }
  
        .host-reviews-section-wrapper {
          width: 100%;
          height: max-content;
          margin-top: 2.5rem;
  
          .host-reviews-heading {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 1rem;
          }
  
          .host-reviewer-container {
            width: 100%;
            height: max-content;
            padding-bottom: 5rem;
  
            .host-reviewer-card {
              width: 100%;
              height: max-content;
              background-color: #fff;
              padding: 1rem 1.5rem;
              border-radius: 25px;
              box-shadow: 0.1px 0.1px 5px #9494942d, -0.1px -0.1px 5px #9494942d;
              margin-bottom: 1rem;
  
              .reviewer-card-upper-section {
                width: 100%;
                height: max-content;
                justify-content: space-between;
  
                .reviewer-name-and-profile-pic {
                  width: max-content;
                  height: max-content;
                  justify-content: flex-start;
                  gap: 1rem;
  
                  .reviewer-profile-pic {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    overflow: hidden;
  
                    img {
                      width: 100%;
                    }
                  }
  
                  .reviewer-name-rating-star {
                    width: max-content;
                    height: max-content;
  
                    .reviewer-name {
                      font-size: 16px;
                      font-weight: 400;
                    }
  
                    .rating-stars {
                      justify-content: flex-start;
                      gap: 0.1rem;
                      color: #feca03;
                    }
                  }
                }
  
                .review-date {
                  height: 100%;
                  color: rgba(128, 128, 128, 0.661);
                  font-size: 14px;
                  align-items: flex-start;
                }
              }
  
              .review-text {
                font-size: 14px;
                font-weight: 400;
                margin-top: 0.75rem;
              }
            }
          }
        }
      }
  
      .host-sevice-details-wrapper {
        width: 40%;
        height: max-content;
        // background-color: green;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
  
        .check-in-out-wrapper {
          width: 90%;
          height: max-content;
          background-color: #fff;
          // align-self: center;
          border: 1px solid black;
          border-radius: 15px;
          margin: 2.5rem 0;
  
          .check-in-wrapper,
          .check-out-wrapper {
            width: 50%;
            padding: 0.5rem;
            padding-left: 0.9rem;
  
            p {
              margin: 0;
              padding: 0;
              font-size: 14px;
            }
  
            .ant-picker {
              &:focus-within {
                border-color: #1890ff; // Change border color when focused
                box-shadow: none; // Add a shadow on focus
              }
          
              // Target the input within the DatePicker
              .ant-picker-input > input {
                &:focus {
                  color: #000; // Change text color when input is focused
                  box-shadow: none;
                  border: none;
                }
              }
            }
  
            .datepicker {
              width: 100%;
              padding: 0.5rem 0rem;
              padding-right: 1.5rem;
              border: none;
              outline: none;
  
              &:focus {
                outline: none;
                border: none;
                box-shadow: none;
                background-color: orange;
              }
            }
  
            &:first-child {
              border-right: 1px solid black;
            }
          }
        }
  
        .host-availability-wrapper {
          width: 100%;
          height: max-content;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
  
          .host-availability-heading {
            min-width: 60%;
            background-color: #000;
            margin-bottom: -0.75rem;
            padding: 0.5rem 0;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #fff;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
          }
  
          .host-availability-calendar {
            min-width: 60%;
            height: max-content;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
  
        .css-1aqny2q-MuiPickersCalendarHeader-root {
          background-color: #feca03;
        }
  
        .services-and-rates {
          width: 90%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 38px;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
          background-color: white;
          padding-top: 5px;
          padding-bottom: 20px;
  
          .services-heading {
            width: 96%;
            color: #fff;
            text-align: center;
            border-radius: 30px;
            display: flex;
            justify-content: center;
  
            h1 {
              width: 100%;
              background-color: #000;
              border-radius: 30px;
              margin-top: 3px;
              padding: 13px;
              font-size: 25px;
              font-family: "RecoletaSemiBold";
            }
          }
  
          .services-sub-heading {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 2px;
            width: 80%;
            margin-top: 10px;
            font-family: "RecoletaSemiBold";
            border-bottom: 1px solid black;
            padding-bottom: 10px;
            padding-left: 0;
  
            li {
              font-size: 16px;
              font-weight: 600;
            }
          }
  
          .housing-details {
            width: 80%;
            border-bottom: 1px solid black;
  
            h1 {
              font-size: 20px;
              margin-bottom: 20px;
              font-weight: 600;
            }
  
            .housing-item {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
  
              img {
                width: 30px;
              }
  
              ul {
                list-style-type: none;
                padding-left: 10px;
  
                li {
                  font-size: 18px;
  
                  &:nth-child(2) {
                    color: gray;
                    font-weight: 100;
                  }
                }
              }
            }
          }
  
          .services {
            width: 80%;
            margin-top: 15px;
            border-bottom: 1px solid black;
  
            h1 {
              font-size: 20px;
              margin-bottom: 20px;
              font-weight: 600;
            }
  
            ul {
              padding-left: 1rem;
  
              li {
                margin-bottom: 13px;
                margin-top: -0.5rem;
                font-size: 18px;
                list-style-type: circle;
  
                img {
                  width: 22px;
                  margin-right: 8px;
                }
              }
            }
          }
  
          .pets-at-home {
            width: 80%;
            margin-top: 15px;
  
            h1 {
              font-size: 20px;
              margin-bottom: 10px;
              font-weight: 600;
            }
  
            ul {
              list-style-type: none;
              padding-left: 0;
  
              li {
                margin-bottom: 13px;
                font-size: 18px;
  
                img {
                  width: 22px;
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
  
  .book-now-button {
    width: 40%;
    height: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    border-top-left-radius: 27px;
    border-top-right-radius: 27px;
    position: fixed;
    bottom: 0;
    right: 5%;
    padding: 10px 50px;
    z-index: 99;
  
    .left-part {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
  
      p {
        font-size: 16px;
        color: #fff;
        margin: 0;
        font-family: "RecoletaSemiBold";
  
        //  &:nth-child(2),
        //  &:nth-child(3) {
        //      font-size: 16px;
        //      color: green;
        //      font-family: "RecoletaSemiBold";
  
        //  }
      }
    }
  
    .host-profile-book-now-btn {
      padding: 10px 35px;
      border-radius: 38px;
      background-color: #feca03;
      border: none;
      font-family: "RecoletaSemiBold";
      a {
        text-decoration: none;
        color: #000;
        font-family: "RecoletaSemiBold";
        font-size: 16px;
      }
    }
  }
  