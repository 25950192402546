* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: whitesmoke;
}

.best_host_heading_wrapper {
  width: 100%;
  height: max-content;
  padding: 2.5rem 6.5rem;
  padding-bottom: 0;

  h2 {
    width: 100%;
    height: max-content;
    padding: 0.7rem 1rem;
    font-size: 24px;
    border-bottom: 1px solid #feca05;
    border-top: 1px solid #feca05;
  }
}

.loading_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;

  width: 100%;

  .loading-image {
    width: 70px;

    height: 70px;

    border-radius: 50%;
    border: 2px dashed #f0ad4e;
    animation: rotate 1s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 80%;
      border-radius: 50%;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.third_section_skeleton {
  display: flex;
  justify-content: center;
  width: 100%;
  height: max-content;
  background-color: whitesmoke;
  margin-bottom: 2rem;
  margin-top: 5px;
  padding-top: 75px;

  .third_section_contianer_skeleton {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 85%;
    flex-wrap: wrap;

    .best_host_card_skeleton {
      width: 23%;
      height: 335px;

      .best_host_card_img {
        width: 100%;
        height: 40%;
        text-align: center;

        .skell {
          width: 90%;
          height: 90%;
          border-radius: 20px;
        }
      }

      .best_host_card_content_skeleton {
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .skell-cont {
          width: 90%;
          height: 10%;
          border-radius: 20px;
        }
      }
    }
  }
}

.filter_cover_image {
  // background-color: orange;
  width: 100%;
  padding: 2rem 0;
  margin-top: 2.5rem;

  img {
    width: 40%;
  }
}

.third_section {
  display: flex;
  justify-content: center;
  width: 100%;
  height: max-content;
  padding: 2.5rem 0;

  .third_section_contianer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 85%;
    height: auto;
    border-radius: 20px;

    .card-best-host {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 25px;
    }
  }
}
