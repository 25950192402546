* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.ant-message {
  font-size: 18px;
  padding: 0.5rem;
}

.dashboard {
  // border: 1px solid red;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  h5 {
    width: 100%;
    font-family: "RecoletaSemiBold";
  }

  h6 {
    width: 100%;
    font-family: "RecoletaRegular";
  }

  .dashboard_data {
    width: 100%;
    // border: 1px solid green;
    display: flex;
    gap: 15px;

    .totalBooking {
      width: 25%;
      // border: 1px solid black;
      box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1rem;
      gap: 10px;
      border-radius: 22px;

      .dashboard_icon {
        color: #feca03;
      }

      .dashboar_count {
        font-size: 25px;
        font-family: "RecoletaSemiBold";
      }

      p {
        font-size: 15px;
      }
    }
  }
}

.adjustavailabilty {
  //border: 1px solid red;
  width: 100%;
  height: auto;
  display: flex;

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background-color: #f0f0f0;
  }
  .adjustcontainer {
    width: 98%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    gap: 15px;

    h5 {
      font-family: "RecoletaSemiBold";
    }

    h6 {
      font-family: "RecoletaRegular";
    }

    .calneder {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      gap: 10px;
      // border: 1px solid red;

      .calender_main {
        // border: 1px solid green;
        width: max-content;
        border-radius: 22px;
        height: auto;
        // height: 450px;

        .update-availability-button {
          background-color: orange;
          width: 100%;
          border-radius: 0 0 22px 22px;
          
          button {
            width: 100%;
            margin: 0;
            padding: 0.7rem 0;
            margin-top: -0.4rem;
            border: none;
            outline: none;
            background-color: #feca03;
            border-radius: 0 0 22px 22px;
            font-weight: 600;
          }
        }

        .react-datepicker__header {
          width: 400px !important;
          // background-color: #2980b9 !important;
          color: white !important;
          border-radius: 12px !important;
          margin: 0;
          padding: 0;
          font-family: "RecoletaSemiBold";
          border-radius: 38px !important;
        }

        .react-datepicker__current-month {
          // background-color: #e74c3c;
          /* Change to your desired background color */
          color: white;
          padding: 1rem;
          width: 100% !important;
          background-color: black;
          padding: 2rem;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          font-family: "RecoletaSemiBold";
          /* Change text color if needed */
        }

        .react-datepicker {
          // border-radius: 38px;
          width: 400px !important;
          // background-color: yellow !important;
          color: white !important;
          border: none !important;
          font-size: 18px !important;
          margin: 0;

          font-family: "RecoletaSemiBold";
        }

        .react-datepicker__day {
          /* Adjust the padding properties as needed */
          padding: 7px;
          /* Example: Add 5px of padding around each date */
        }

        .react-datepicker__navigation-icon::before {
          /* Adjust the positioning using CSS properties like `top`, `left`, etc. */
          top: 70%;
          /* Example: Change the vertical position */
          left: 0;
        }

        .react-datepicker__day-names {
          background-color: #fbd61b;
          font-size: 17px;
          width: 400px !important;
          /* Change to your desired background color */
          color: black !important;
          font-family: "RecoletaSemiBold";
        }

        .green-background {
          background-color: green !important;
          color: white;
          /* Optional: Set text color to white for better visibility */
        }

        .react-datepicker__day-name {
          // background-color: #f39c12;
          /* Change to your desired background color */
          color: black;
          padding: 1rem;
          font-family: "RecoletaSemiBold";

          //   width: 100% !important;
          /* Change text color if needed */
        }
      }

      .calender_heading {
        // border: 1px solid red;
        width: 40%;
        display: flex;
        height: auto;
        // justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 9px;

        box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);
        background-color: white;
        border-radius: 22px;

        h5 {
          background-color: black;
          width: 94%;
          margin: 8px;
          padding: 0.8rem;
          border-radius: 38px;
          color: white;
          text-align: center;
        }

        .data-date {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 10px;

          li {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            .cancel-icon {
              color: red;
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}
