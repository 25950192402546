@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* For Chrome, Safari, Edge, and other WebKit browsers */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
body {
  background-color: #f8f8f8 !important;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Roboto", sans-serif !important; */
  font-family: "Poppins", sans-serif !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}