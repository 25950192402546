* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.coffee-soda {
  font-family: "Coffee Soda", sans-serif !important;
  font-size: 40px !important;
}

.woofily-commitments-wrapper {
  width: 100%;
  height: max-content;
  padding-top: 65px;

  .first-section-wrapper {
    width: 100%;
    height: 36rem;
    position: relative;
    background-color: #fcedcf;
    // color: #fff;

    .image-overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .info-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 5% 0 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .second-section-wrapper,
  .fourth-section-wrapper {
    width: 100%;
    height: max-content;
    flex-direction: column;
    padding: 5rem 10%;

    .central-heading-container {
      width: 100%;
      height: max-content;
      text-align: center;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #e89f0d;

        img {
          width: 10%;
          right: 13.5%;
          top: -150%;
        }
      }
    }

    .needs-info-cards-wrapper {
      width: 100%;
      height: max-content;
      align-items: flex-start;
      gap: 5rem;
      margin-top: 3rem;

      .left-cards-container,
      .right-cards-container {
        width: calc(100% / 2.5);

        .needs-card {
          width: 100%;
          height: calc(375px + 5rem);
          border-radius: 40px;
          padding: 2.5rem;
          margin-bottom: 2.5rem;
          color: #000;
          background-color: #fcedcf;
          flex-direction: column;
          text-align: center;
          gap: 2.5rem;

          .card-heading {
            justify-content: flex-start;
            gap: 1.5rem;
            margin-left: 1rem;

            h3 {
              font-size: 22px;
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .third-section-wrapper {
    width: 100%;
    padding: 0 10%;
    height: max-content;

    h1 {
      width: 100%;
      text-align: center;
    }

    .sub-head {
      width: 100%;
      text-align: center;
    }

    .activities-wrapper {
      width: 100%;
      gap: 5rem;
      align-items: flex-start;
      margin-top: 5rem;

      .activity-card {
        align-items: flex-start;
        gap: 1.5rem;

        .media-wrapper {
          width: 20%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
          }
        }

        .info-wrapper {
          width: 80%;

          .info-head {
            font-weight: 600;
          }

          .skill-content {
            font-size: 14px;
          }
        }
      }
    }
  }

  .fourth-section-wrapper {
    .needs-info-cards-wrapper {
      .left-cards-container,
      .right-cards-container {
        .needs-card {
          // height: calc(400px + 5rem);

          p {
            font-size: 14px;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }

  .fifth-section-wrapper {
    width: 100%;
    height: max-content;

    .top-section-wrapper {
      width: 100%;
      height: max-content;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      padding: 0 5%;
      text-align: center;
      margin-bottom: 1.5rem;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #000;
        font-size: 40px;
        text-align: center;
      }
    }
    .tummy-info-container {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7.5rem;
      padding: 0 10%;

      .tummy-info {
        width: 33.33%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;

        img {
          width: 13%;
          margin-bottom: 1rem;
        }

        .tummy-sub-heading {
          font-size: 18px;
        }

        p {
          font-size: 14px;
        }
      }
    }
    .last-info {
      width: 100%;
      height: max-content;
      padding: 1.5rem;
      text-align: center;
      padding-bottom: 5rem;
    }
  }
}
