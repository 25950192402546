* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.client-profile-sections-wrapper {
  max-width: 100%;
  height: max-content;
  padding-top: 110px;
  padding: 65px 5% 2.5rem 5%;

  .first-section-cover-pic-wrapper {
    width: 100%;
    height: max-content;
    padding: 2rem 0;
    // background-color: orange;
    position: relative;

    .parent-cover-pic-container {
      width: 100%;
      height: 24vw;
      background-color: #fff;
      border-radius: 3vw;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      .edit_cover_pic {
        position: absolute;
        right: 1.5rem;
        bottom: 1.5rem;
        background-color: rgba(0, 0, 0, 0.563);
        border-radius: 50%;
        padding: 0.7rem;
        cursor: pointer;

        .profile_cover_icon {
          color: white;
        }
      }
    }

    .parent-profile-pic-container {
      width: 11vw;
      height: 11vw;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      bottom: 2rem;
      left: 4vw;
      transform: translate(0, 35%);
      overflow: hidden;
      border: 2px solid #fff;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }

      .edit_image_text {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.7);
        /* Add your desired background color for the text */
        color: white;
        /* Add your desired text color */
        padding: 8px;
        // border-radius: 50%;
        font-size: 12px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .second-section-content-sections-wrapper {
    width: 100%;
    height: max-content;
    gap: 2.5rem;
    align-items: flex-start;

    .parent-details-wrapper {
      width: 60%;
      height: max-content;
      // background-color: orange;

      .parent-name-location-container {
        width: 100%;
        height: max-content;
        padding-top: 3rem;

        .parent-name {
          font-size: 28px;
        }

        .parent-id {
          font-size: 16px;
          color: gray;
        }

        .parent-location {
          font-size: 16px;
        }
      }

      .profile_complete_progressbar {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 0.5rem;
        margin-bottom: 1rem;

        .progress {
          width: 100%;

          .progress_bar {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background-color: #feca03;
            padding-right: 1.5rem;
            font-weight: 500;
          }
        }
      }

      .suggestion_root_flex1_button {
        display: flex;
        gap: 20px;
        align-items: center;
        margin-bottom: 1rem;

        .suggestion_button {
          border-radius: 20px;

          &:nth-child(1),
          &:nth-child(3) {
            button {
              background-color: #feca03;
              padding: 10px 28px 10px 28px;
              outline: none;
              border: none;
              border-radius: 58px;
              font-family: "RecoletaRegular";
            }
          }

          &:nth-child(2),
          &:nth-child(4) {
            button {
              background: var(--black, #222);
              padding: 10px 28px 10px 28px;
              outline: none;
              border: none;
              border-radius: 58px;
              font-family: "RecoletaRegular";
              color: white;
            }
          }
        }
      }

      .about-parent-container {
        width: 100%;
        height: max-content;
        padding: 1rem 0 0 0;
        border-bottom: 1px solid #000;
        border-top: 1px solid #000;
        padding-bottom: 0.8rem;

        .about-heading {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 0.25rem;
        }

        .about-text {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 0.75rem;
        }

        .parent-gender,
        .parent-age,
        .parent-profession {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 0.25rem;

          span {
            font-weight: 500;
          }
        }
      }

      .parent-photos-wrapper {
        width: 100%;
        height: max-content;
        padding-top: 1rem;

        .parent-post-pics-heading {
          font-size: 22px;
          font-weight: 600;
          padding-bottom: 0.75rem;
          margin-bottom: 0.75rem;
          border-bottom: 1px solid black;
        }

        .parent-photos-container {
          width: 100%;
          height: max-content;
          flex-wrap: wrap;
          gap: 1rem;
          justify-content: flex-start;

          .parent-pic {
            width: 200px;
            height: 200px;
            overflow: hidden;
            border-radius: 5px;

            img {
              width: auto;
              height: auto;
              object-fit: cover !important;
            }
          }
        }

        .see-more-pics-button-wrapper {
          width: 100%;
          height: max-content;
          margin-top: 1rem;

          .see-more-pics-button {
            background-color: #feca03;
            border: none;
            outline: none;
            padding: 0.5rem 2.5rem;
            font-size: 16px;
            font-weight: 500;
            border-radius: 27px;
            box-shadow: 0.1px 0.1px 5px #feca03, -0.1px -0.1px 5px #feca03;
          }
        }
      }
    }

    .parent-sevice-details-wrapper {
      width: 40%;
      height: max-content;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;

      .parent_pets_wrapper {
        width: 90%;
        height: max-content;
        text-align: center;
        border-radius: 25px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 5px;
        padding-bottom: 0px;
        background-color: #fff;

        h3 {
          width: 99%;
          background-color: black;
          padding: 1rem;
          color: white;
          border-radius: 37px;
          font-family: "RecoletaRegular";
        }

        .parent_pets {
          height: max-content;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 1rem;
          padding-top: 0.5rem;
          gap: 10px;

          .parent_pet_img_sec {
            width: 80px;
            height: max-content;
            border-radius: 50%;

            img {
              width: 72px;
              height: 72px;
              border: 2px solid #feca03;
              border-radius: 50%;
            }

            .parent_pet_img_sec_name {
              font-family: "RecoletaRegular";
              padding-top: 10px;
              text-transform: capitalize;
            }
          }

          .add_pet_btn {
            width: 72px;
            height: 72px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;

            a {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              .additon_button {
                position: absolute;
                right: 0;
                bottom: 0;
                color: black;
              }

              img {
                height: 100%;
                width: 100%;
                //   border: 1px solid red;
                border-radius: 0%;
              }
            }
          }
        }

        .see_more_pets {
          font-family: "RecoletaRegular";
          color: #feca03;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }
    }
  }
}
