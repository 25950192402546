.download-invoice{
  margin-left:120px;
  .download-invoice-link{
      color:green;
      font-weight:700;
      font-size:20px;
      border:none;
  }
  }

.summary {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  // background-color: orange;

  .summary-heading {
    width: 85%;
    height: auto;

    h4 {
      font-size: 28px;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      padding: 0.5rem;
    }
  }
}

.order-summary-details {
  max-width: 1128px;;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  .section.pet-info {
      .pet-details-row {
          display: flex;
          flex-direction: row; 
          align-items: center; 
          gap: 20px; 
      }

      .pet-image {
          width: 100px;
          height: 100px;
          object-fit: cover;
          border-radius: 50%; 
      }

      p {
          margin: 0; 
      }
  }

  .section.host-info {
      .host-details-row {
          display: flex;
          flex-direction: row; 
          align-items: center; 
          gap: 20px; 
      }

      .host-image {
          width: 100px;
          height: 100px;
          object-fit: cover;
          border-radius: 50%; 
      }

      p {
          margin: 0; 
      }
  }  
  
  .section.order-details {
      .order-details-row {
          display: flex;
          flex-direction: row; 
          align-items: center; 
          gap: 20px; 
      }
      p {
          margin: 0; 
      }
  }   

  .section {
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
      h2 {
          font-size: 1.5rem;
          color: #333;
          margin-bottom: 10px;
      }

      p {
          font-size: 1rem;
          color: #555;
      }

      &.pet-info, &.host-info {
          display: flex;
          align-items: center;

          img {
              border-radius: 50%;
              width: 80px;
              height: 80px;
              margin-right: 15px;
          }
      }
  }

  .stars {
      .star {
          font-size: 1.5rem;
          color: #ddd;
          &.filled {
              color: #ffd700;
          }
      }
  }

  .order-details {
      p {
          margin: 5px 0;
      }
  }

}
@media (max-width: 1290px) {
  .order-summary-details {
      max-width: 994px !important; 
    }
}







  

  