.PetFormOne_root_nect_page {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .PetFormOne_root_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 1.5rem;
    // align-items: center;

    .PetFormOne_root_flex1 {
      width: 50%;
      // border: 1px solid red;

      .PetFormOne_root_flex1inp_next_page {
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
          font-size: 20px;
          font-family: "RecoletaSemiBold";
          margin-top: 20px;
          margin-left: 20px;
        }

        p {
          font-family: "RecoletaSemiBold";
          font-family: "RecoletaRegular";
        }

        input {
          width: 88%;
          border-radius: 38px;
          padding: 12px 30px 12px 30px;
          border: none;
          outline: none;
          font-family: "RecoletaRegular";
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
        }

        .petfrom_button {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          // border: 1px solid green;
          align-items: center;

          gap: 15px;

          .selected-yes {
            // background-color: #21B439;
            background-color: #feca03;
            color: whitesmoke;
          }

          .selected-no {
            // background-color: #F44;
            background-color: #feca03;
            color: white;
          }

          button {
            border: none;
            outline: none;
            width: 43%;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
            padding: 10px 0px 10px 0px;
            border-radius: 38px;
            // background-color: white;
            font-family: "RecoletaRegular";
            font-size: 20px;
          }
        }

        .profile_location_contianer {
          display: flex;
          background-color: white;
          width: 88%;
          border-radius: 35px;

          .profile_location_img {
            width: 60px;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 60%;
            }
          }

          .profile_location_select {
            width: 100%;
            border-radius: 35px;

            select {
              padding: 12px;
              width: 100%;
              border-radius: 35px;
              border: none;
              outline: none;
              box-shadow: none;
              font-family: "RecoletaRegular";

              option {
                border-radius: 35px;
              }
            }
          }

          .profile_location_inp {
            width: 100%;
            border-radius: 35px;

            input {
              padding: 12px;
              width: 100%;
              border-radius: 35px;
              border: none;
              outline: none;
              box-shadow: none;
              font-family: "RecoletaRegular";
            }
          }
        }
      }

      .PetFormOne_root_flex2inp_next_page {
        display: flex;
        flex-direction: column;
        gap: 10px;
        // background-color: orange;

        label {
          font-size: 18px;
          margin-left: 20px;
          font-family: "RecoletaSemiBold";
          margin-top: 5px;
        }

        input {
          width: 90%;
          border-radius: 38px;
          padding: 12px 30px 12px 30px;
          border: none;
          outline: none;
          font-family: "RecoletaRegular";
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
        }

        .petfrom_button {
          width: 90%;
          display: flex;
          justify-content: space-between;
          // border: 1px solid green;
          align-items: center;

          // gap: 15px;
          input {
            width: 62%;
            border-radius: 38px;
            padding: 10px 20px 10px 20px;
            border: none;
            outline: none;
            font-family: "RecoletaRegular";
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
          }

          button {
            border: none;
            outline: none;
            width: 35%;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
            padding: 8px 0px;
            border-radius: 38px;
            // background-color: white;
            background-color: #feca03;
            font-family: "RecoletaRegular";

            font-size: 16px;

            &:nth-child(1) {
              background: #56bae7;
              color: white;
            }

            &:nth-child(2) {
              background: #e43ce1;
              color: white;
            }

            &:hover {
              background-color: grey;
              color: white;
            }

            // padding: 8px 3rem 8px 3rem;
          }
        }

        .profile_location_contianer {
          display: flex;
          background-color: white;
          width: 88%;
          border-radius: 35px;

          .profile_location_img {
            width: 60px;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 60%;
            }
          }

          .profile_location_select {
            width: 100%;
            border-radius: 35px;

            select {
              padding: 12px;
              width: 100%;
              border-radius: 35px;
              border: none;
              outline: none;
              box-shadow: none;
              font-family: "RecoletaRegular";

              option {
                border-radius: 35px;
              }
            }
          }

          .profile_location_inp {
            width: 100%;
            border-radius: 35px;

            input {
              padding: 12px;
              width: 100%;
              border-radius: 35px;
              border: none;
              outline: none;
              box-shadow: none;
              font-family: "RecoletaRegular";
            }
          }
        }

        .pet_timing_container {
          width: 100%;
          display: flex;
          justify-content: center;

          .pet_timing {
            width: 100%;
            // border: 1px solid red;
            text-align: center;

            label {
              width: 100%;

              font-family: "RecoletaRegular";
            }

            button {
              border: none;
              outline: none;
              width: 90%;
              font-size: 16px;
              font-family: "RecoletaRegular";
              padding: 6px 0px 6px 0;
              border-radius: 38px;
              box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }

    .PetFormOne_root_flex2_next_page {
      width: 50%;
      // border: 1px solid blue;
      // line-height: 40px;

      .PetFormOne_root_flex2inp_next_page {
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
          font-size: 18px;
          font-family: "RecoletaSemiBold";
          margin-top: 10px;
          margin-left: 20px;
        }

        input {
          width: 100%;
          border-radius: 38px;
          padding: 12px 30px 12px 30px;
          border: none;
          outline: none;
          font-family: "RecoletaRegular";
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
        }

        .petfrom_button {
          width: 100%;
          display: flex;
          justify-content: space-between;
          // border: 1px solid green;
          align-items: center;

          // gap: 15px;
          input {
            width: 64%;
            border-radius: 38px;
            padding: 12px 30px 12px 30px;
            border: none;
            outline: none;
            font-family: "RecoletaRegular";
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
          }

          button {
            border: none;
            outline: none;
            width: 30%;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
            padding: 10px 0px 10px 0px;
            border-radius: 38px;
            // background-color: white;
            background-color: #feca03;
            font-family: "RecoletaRegular";

            font-size: 20px;

            &:nth-child(1) {
              background: #56bae7;
              color: white;
            }

            &:nth-child(2) {
              background: #e43ce1;
              color: white;
            }

            &:hover {
              background-color: grey;
              color: white;
            }

            // padding: 8px 3rem 8px 3rem;
          }
        }

        .profile_location_contianer {
          display: flex;
          background-color: white;
          width: 88%;
          border-radius: 35px;

          .profile_location_img {
            width: 60px;

            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 60%;
            }
          }

          .profile_location_select {
            width: 100%;
            border-radius: 35px;

            select {
              padding: 12px;
              width: 100%;
              border-radius: 35px;
              border: none;
              outline: none;
              box-shadow: none;
              font-family: "RecoletaRegular";

              option {
                border-radius: 35px;
              }
            }
          }

          .profile_location_inp {
            width: 100%;
            border-radius: 35px;

            input {
              padding: 12px;
              width: 100%;
              border-radius: 35px;
              border: none;
              outline: none;
              box-shadow: none;
              font-family: "RecoletaRegular";
            }
          }
        }

        .pet_timing_container {
          width: 100%;
          display: flex;
          justify-content: center;

          .pet_timing {
            width: 100%;
            // border: 1px solid red;
            text-align: center;

            label {
              width: 100%;

              font-family: "RecoletaRegular";
            }

            button {
              border: none;
              outline: none;
              width: 90%;
              font-size: 16px;
              font-family: "RecoletaRegular";
              padding: 6px 0px 6px 0;
              border-radius: 38px;
              box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }

    .submit_pet_buttonS {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;
      margin-bottom: 1rem;
      // border: 1px solid red;
      // text-align: center;

      button {
        border: none;
        outline: none;
        background: #feca03;
        border-radius: 32px;
        padding: 12px 0px 12px 0px;
        font-family: "RecoletaRegular";

        width: 24%;
      }
    }
  }
}
