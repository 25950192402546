* {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
  }
  
  .ordersummary {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .ordersummary_container {
        margin-top: 3rem;
        width: 80%;
        height: 90%;
        display: flex;
        flex-wrap: wrap;
  
        .order_sum_flex1 {
            width: 50%;
            height: auto;
            border-radius: 12px;
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
            // padding: 1rem;
            background-color: white;
            text-align: center;
            display: flex;
            justify-content: flex-start;
            align-content: center;
            flex-direction: column;
  
            .order_sum_flex1-inner {
                background-color: #FFF8E0;
                width: 97%;
                border-radius: 12px;
                align-self: center;
                margin: 2%;
                padding: 1rem;
                display: flex;
                // justify-content: center;
                // align-items: center;
                flex-direction: column;
                gap: 20px;
  
                .order_summmary_heading {
                    width: 100%;
                    border-radius: 12px;
                    display: flex;
                    justify-content: space-between;
  
  
  
                    .booking-name {
                        h3 {
                            font-size: 1.5rem;
                        }
                    }
  
                }
  
                .order_summmary_info {
                    display: flex;
                    justify-content: space-between;
  
                    .booking-name {
                        .booking-name-info {
                            display: flex;
                            justify-content: flex-start;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 1px;
                            margin: 0;
                            padding: 0;
  
                            p {
                                margin: 0;
                                padding: 0;
                                font-size: 13px;
                            }
  
                            h6 {
                                font-family: "RecoletaSemiBold";
                            }
                        }
                    }
  
                    .booking-start-date {
                        .booking-date {
                            display: flex;
                            justify-content: flex-start;
                            flex-direction: column;
                            align-items: flex-end;
                            gap: 1px;
                            margin: 0;
                            padding: 0;
  
                            p {
                                margin: 0;
                                padding: 0;
                                font-size: 13px;
                            }
  
                            h6 {
                                font-family: "RecoletaSemiBold";
                            }
                        }
                    }
                }
            }
  
  
  
            .order_sum_flex1_content {
                width: 100%;
                // border-radius: 38px;
                height: auto;
                // margin-top: 1rem;
                //  border: 1px solid red;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                gap: 5px;
                padding: 2rem;
  
                .order {
                    width: 97%;
                    display: flex;
                    justify-content: space-between;
  
                    h6 {
                        &:nth-child(1) {
  
                            font-family: "RecoletaSemiBold";
                            font-size: 14px;
  
                        }
                    }
  
                    h6 {
                        &:nth-child(2) {
  
                            font-family: "RecoletaRegular";
                            font-size: 14px;
  
                        }
                    }
  
                    //  border: 1px solid green;
  
                }
            }
        }
  
        .order_sum_flex2 {
            width: 50%;
            height: 100%;
            // border: 1px solid red;
  
            display: flex;
            justify-content: center;
            align-items: flex-start;
  
            .order_sum_flex2_container {
                width: 80%;
                // border: 1px solid black;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 38px;
                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
                background-color: white;
  
                h3 {
                    width: 100%;
                    margin: 2px;
                    background-color: black;
                    color: white;
                    border-radius: 38px;
                    padding: 1rem;
                    font-size: 1.6rem;
                    font-weight: 500;
                }
  
                .order_sum_flex2_content {
                    width: 100%;
                    // margin-bottom: 2rem;
                    // margin-top: 1rem;
                    margin: 3rem;
  
                    .order {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-left: 1rem;
                        padding-right: 1rem;
  
                        h4 {
                            font-size: 1.4rem;
                        }
  
                        h5 {
                            font-size: 1rem;
                            font-weight: 400;
                        }
                    }
                }
  
                .order_button {
                    width: 100%;
                    // border: 1px solid red;
                    display: flex;
                    justify-content: center;
                    align-items: center;
  
                    button {
                        width: 40%;
                        border: none;
                        outline: none;
                        background-color: #FECA03;
                        font-family: "RecoletaSemiBold";
                        color: white;
                        border-radius: 38px;
                        padding: 0.6rem;
                    }
                }
            }
        }
  
    }
  
  }