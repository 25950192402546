* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
  color: #000;
  font-size: 48px;
  position: relative;

  img {
    position: absolute;
    width: 18%;
    right: 10%;
    top: -150%;
  }
}

.become-a-verified-pet-host-wrapper {
  width: 100%;
  height: max-content;
  background-color: #f8f8f8;
  padding-top: 65px;

  .first-section-wrapper {
    width: 100%;
    height: calc(60vh + 5rem);
    padding: 2.5rem 10%;

    .info-container {
      width: 40%;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #ff744d;
        font-size: 48px;

        img {
          position: absolute;
          width: 25%;
          right: -25%;
          top: -115%;
        }
      }

      p {
        color: #000;
      }
    }

    .media-container {
      width: 60%;
      height: 100%;
      align-items: flex-start;
      justify-content: flex-end;
      position: relative;

      img {
        width: 85%;
      }

      .cta-btn {
        position: absolute;
        right: 20%;
        bottom: 10%;
        width: max-content;
        height: max-content;
        position: absolute;
        justify-content: flex-start;
        padding: 1rem 1.5rem;
        border-radius: 20px;
        box-shadow: 1px 1px 10px #ff744d;
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(5px);

        &:hover {
          cursor: pointer;
        }

        .cta-icon {
          padding: 0.75rem;
          background-color: #ff744d;
          color: #fff;
          border-radius: 15px;
          margin-right: 1rem;

          .icon {
            font-size: 28px;
          }
        }

        .cta-info {
          width: max-content;
          height: max-content;

          h3 {
            margin: 0;
            padding: 0;
            font-size: 22px;
          }

          p {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }

  .second-section-wrapper {
    width: 100%;
    height: calc(50vh + 5rem);
    padding: 2.5rem 10%;

    .info-container {
      width: 60%;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #9747ff;
        font-size: 48px;

        img {
          position: absolute;
          width: 15%;
          right: -10%;
          top: -120%;
        }
      }

      p {
        color: #000;
      }
    }

    .media-container {
      width: 40%;
      height: 100%;
      align-items: flex-end;
      position: relative;

      img {
        width: 50%;
      }
    }
  }

  .third-section-wrapper {
    width: 100%;
    height: max-content;
    flex-direction: column;
    padding: 2.5rem 10%;

    .central-heading-container {
      width: 100%;
      height: max-content;
      text-align: center;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #c7253f;

        img {
          width: 10%;
          right: 13.5%;
          top: -150%;
        }
      }
    }

    .needs-info-cards-wrapper {
      width: 100%;
      height: max-content;
      align-items: flex-start;
      gap: 1.5rem;
      margin-top: 3rem;

      .left-cards-container,
      .middle-cards-container,
      .right-cards-container {
        width: calc(100% / 3);

        .needs-card {
          width: 100%;
          height: max-content;
          border-radius: 45px;
          padding: 2.5rem;
          margin-bottom: 1.5rem;
          color: #fff;

          p {
            font-size: 14px;
          }

          .card-heading {
            justify-content: flex-start;
            gap: 1.5rem;
            margin-bottom: 1rem;

            img {
              width: 50px;
            }

            h3 {
              font-size: 20px;
              margin: 0;
              padding: 0;
            }
          }
        }

        .card-one {
          background-color: #9747ff;
        }

        .card-two {
          background-color: #c72591;
        }

        .card-three {
          background-color: #3399ff;
        }

        .card-four {
          background-color: #c7253e;
          position: relative;

          .hanging-cat {
            position: absolute;
            top: -53%;
            left: 50%;
            transform: translate(-50%);
          }
        }

        .card-five {
          background-color: #bf00e5;
        }

        .card-six {
          background-color: #e85c0d;
        }

        .card-seven {
          background-color: #e89f0d;
        }
      }

      .middle-cards-container {
        padding-top: 11rem;
      }
    }
  }

  .fourth-section-wrapper {
    width: 100%;
    height: max-content;
    flex-direction: column;
    padding: 2.5rem 10%;

    .central-heading-container {
      width: 100%;
      height: max-content;
      text-align: center;

      h1 {
        font-family: "Coffee Soda", sans-serif !important;
        color: #e89f0d;

        img {
          width: 10%;
          right: 16.5%;
          top: -150%;
        }
      }
    }

    .needs-info-cards-wrapper {
      width: 100%;
      height: max-content;
      align-items: flex-start;
      gap: 2.5rem;
      margin-top: 3rem;

      .left-cards-container,
      .right-cards-container {
        width: calc(100% / 2);

        .needs-card {
          width: 100%;
          height: 200px;
          border-radius: 40px;
          padding: 2.5rem;
          margin-bottom: 2.5rem;
          color: #000;
          background-color: #fcedcf;

          .card-heading {
            justify-content: flex-start;
            gap: 1.5rem;
            margin-left: 1rem;

            h3 {
              font-size: 20px;
              margin: 0;
              padding: 0;
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }

  .fifth-section-wrapper {
    width: 100%;
    padding: 0 10%;
    height: max-content;
    // padding-top: 7.5rem;
    padding-bottom: 7.5rem;

    .more-advice-container {
      width: 100%;
      height: 350px;
      padding: 45px;
      background-color: #C72591;
      border-radius: 45px;

      .media-container {
        width: 50%;
        height: 100%;
      }

      .info-wrapper {
        width: 50%;
        height: max-content;
        color: #fff;

        .bold {
          font-weight: 600;
        }
      }
    }
  }
}
