.community_sidebar {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  padding: 0;
  padding-top: 70px;

  .host_container {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .host_sidebar {
      width: 20%;
      height: calc(100vh - 70px);
      display: flex;
      justify-content: center;
      align-items: flex-start;
      // margin-top: 1rem;
      margin-top: 0rem;
      // background-color: orange;
      position: fixed;
      left: 0;

      .host_sidebar_inner {
        background-color: white;
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0;
        justify-content: center;
        // border: 1px solid green;
        box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);
        // background-color: orange;

        ul {
          width: 100%;
          height: 100%;
          list-style-type: none;
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          // background-color: orange;

          .categories {
            height: max-content;
            width: 100%;
            background-color: #000;
            color: #fff;
            text-transform: uppercase;
            font-size: 20px;
            padding: 0.5rem 0;
            font-weight: 500;
            letter-spacing: 1px;
          }

          li {
            height: max-content;
            width: 100%;
            display: flex;
            //border: 1px solid blue;
            align-items: center;
            justify-content: center;
            margin: 0;
            // padding: 1rem;
            list-style-type: none;

            a {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              text-decoration: none;
              gap: 10px;
              color: black;
              padding: 0.5rem 0;
              padding-left: 45px;

              h5 {
                font-family: "RecoletaRegular";
                font-size: 18px;
                font-weight: 400;
                margin-top: 10px;
              }
            }

            .active {
              background-color: #feca05;
            }
          }
        }
      }
    }

    .host_outlet {
      margin-left: 20%;
      width: 80%;
      height: auto;
      // border: 1px solid green;
      display: flex;
      // justify-content: center;
      align-items: flex-start;
      // background-color: green;
      padding-bottom: 1rem;
      padding-left: 2.5rem;

      .host_outlet_innr {
        width: 100%;
        height: auto;
        margin-top: 2rem;
        // background-color: green;
      }
    }
  }
}

@media screen and (min-width: 650px) and (max-width: 1200px) {
  .community_sidebar {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .host_container {
      width: 95%;
      // border: 1px solid;
      height: auto;
      display: flex;
      gap: 2rem;
      align-items: flex-start;
      justify-content: center;
      // flex-wrap: wrap;

      .host_sidebar {
        width: 50%;
        // border: 1px solid red;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        // margin-top: 1rem;
        margin-top: 2rem;

        .host_sidebar_inner {
          background-color: white;
          border-radius: 18px;
          width: 80%;
          display: flex;
          padding: 1rem;
          justify-content: center;
          //border: 1px solid green;
          box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);

          ul {
            width: 100%;
            height: auto;
            list-style-type: none;
            padding: 0;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;

            li {
              height: 40px;
              width: 100%;
              display: flex;
              //border: 1px solid blue;
              align-items: center;
              justify-content: center;
              margin: 0;
              // padding: 1rem;
              list-style-type: none;

              a {
                width: 90%;
                height: 40px;
                // border: 1px solid green;
                // padding: 4px 4px 4px 4px;
                border-radius: 12px;
                background: #fff8e0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 10px;
                text-decoration: none;
                gap: 20px;
                color: black;

                h5 {
                  font-family: "RecoletaRegular";
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .host_outlet {
        width: 50%;
        height: auto;
        // border: 1px solid green;
        display: flex;
        // justify-content: center;
        align-items: flex-start;

        .host_outlet_innr {
          width: 95%;
          height: auto;
          margin-top: 2rem;
        }
      }
    }
  }
}

@media screen and (max-width: "550px") {
  .community_sidebar {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .host_container {
      width: 90%;
      height: auto;
      display: flex;
      gap: 2rem;
      align-items: flex-start;
      justify-content: center;
      //     border: 1px solid red;
      flex-wrap: wrap;

      .host_sidebar {
        width: 100%;
        // border: 1px solid red;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        // margin-top: 1rem;
        margin-top: 1rem;

        .host_sidebar_inner {
          background-color: white;
          border-radius: 18px;
          width: 90%;
          display: flex;
          padding: 1rem;
          justify-content: center;
          // border: 1px solid green;
          box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.1);

          ul {
            width: 100%;
            height: auto;
            list-style-type: none;
            padding: 0;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 15px;

            li {
              height: 40px;
              width: 100%;
              display: flex;
              //border: 1px solid blue;
              align-items: center;
              justify-content: center;
              margin: 0;
              // padding: 1rem;
              list-style-type: none;

              a {
                width: 90%;
                height: 40px;
                // border: 1px solid green;
                // padding: 4px 4px 4px 4px;
                border-radius: 12px;
                background: #fff8e0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 10px;
                text-decoration: none;
                gap: 20px;
                color: black;

                h5 {
                  font-family: "RecoletaRegular";
                  font-size: 18px;
                }
              }
            }
          }
        }
      }

      .host_outlet {
        width: 90%;
        height: auto;
        //     border: 1px solid green;
        display: flex;
        // justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;

        .host_outlet_innr {
          width: 100%;
          height: auto;
          margin-top: 2rem;
        }
      }
    }
  }
}
