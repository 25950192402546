* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

// background: #d9d9d9;

.footer-section-wrapper {
  width: 100%;
  height: max-content;
  padding: 0 5%;
  background-color: #d9d9d94f;

  .footer-top-section {
    width: 100%;
    height: max-content;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2.5rem 0;

    ul {
      width: (100%/5);
      // border: 1px solid black;

      &:nth-child(1) {
        width: (100%/6);
      }

      &:nth-child(2) {
        width: (100%/4);
      }

      &:nth-child(3) {
        width: (100%/4);
      }

      li {
        list-style-type: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;

        a {
          text-decoration: none;
          color: #000;

          &:hover {
            text-decoration: underline;
          }
        }

        &:first-child {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 8px;
        }
      }
    }
  }

  .mobile-extended-footer {
    display: none;
  }

  .footer-bottom-section {
    width: 100%;
    height: max-content;
    justify-content: space-between;
    padding: 1rem 0;
    border-top: 1px solid black;

    .bottom-footer-left-section {
      width: 50%;
      justify-content: left;
      gap: 0.25rem;

      p {
        font-size: 14px;
        color: #000;
        margin: 0;
        padding: 0;

        .dot-icon {
          margin-right: 0.25rem;
        }

        a {
          text-decoration: none;
          color: #000;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .bottom-footer-right-section {
      width: 50%;
      height: 100%;
      justify-content: flex-end;
      gap: 1rem;

      .social-icon {
        padding: 0.25rem;
        background-color: #000;
        border-radius: 50%;
        transition: 0.5s;

        &:hover {
          border-radius: 5px;
        }

        a {
          margin: 0;
          padding: 0;
          text-decoration: none;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            color: #fff;
            font-size: 20px;
          }
        }
      }

      .instagram {
        background: radial-gradient(
          circle at 0% 0%,
          #fdf497 0%,
          #fdf497 5%,
          #fd5949 45%,
          #d6249f 60%,
          #285aeb 90%
        );
      }

      .facebook {
        background-color: #0765ff;
      }

      .linkedin {
        background-color: #0173b1;
      }
    }
  }
}

@media only screen and (max-width: 768.98px) {
  .footer-section-wrapper {
    width: 100%;
    height: max-content;
    padding: 0 5%;
    background-color: #d9d9d94f;

    .footer-top-section {
      display: none;
    }

    .mobile-extended-footer {
      gap: 0.5rem;
      margin: 0;
      padding: 1rem 0;
      display: flex;
  
      p {
        font-size: 14px;
        color: #000;
        margin: 0;
        padding: 0;
  
        .dot-icon {
          margin-right: 0.25rem;
        }
  
        a {
          text-decoration: none;
          color: #000;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .footer-bottom-section {
      width: 100%;
      height: max-content;
      justify-content: space-between;
      padding: 1rem 0;
      border-top: 1px solid black;

      .bottom-footer-left-section {
        width: 75%;
        justify-content: left;
        gap: 0.25rem;
        align-items: center;

        .privacy,
        .terms,
        .sitemap,
        .company-details {
          display: none;
        }

        p {
          font-size: 14px;
          color: #000;
          margin: 0;
          padding: 0;

          .dot-icon {
            margin-right: 0.25rem;
          }

          a {
            text-decoration: none;
            color: #000;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .bottom-footer-right-section {
        width: 25%;
        height: 100%;
        justify-content: flex-end;
        gap: 1rem;

        .social-icon {
          padding: 0.25rem;
          background-color: #000;
          border-radius: 50%;
          transition: 0.5s;

          &:hover {
            border-radius: 5px;
          }

          a {
            margin: 0;
            padding: 0;
            text-decoration: none;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
              color: #fff;
              font-size: 20px;
            }
          }
        }

        .instagram {
          background: radial-gradient(
            circle at 0% 0%,
            #fdf497 0%,
            #fdf497 5%,
            #fd5949 45%,
            #d6249f 60%,
            #285aeb 90%
          );
        }

        .facebook {
          background-color: #0765ff;
        }

        .linkedin {
          background-color: #0173b1;
        }
      }
    }
  }
}
