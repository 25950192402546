* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.slider_button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  border-radius: 50%;
  border: none;
  background-color: rgba(0, 0, 0, 0.426);
  color: #fff;
  z-index: 999;
}

.previous {
  left: 1.5%;
}

.next {
  right: 1.5%;
}

.main_choose_pet_section {
  align-self: center;
  width: 100%;
  overflow-x: hidden;
  min-height: max-content;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 110px;

  .choose_pet_to_host {
    width: 90%;
    min-height: max-content;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .heading {
      max-width: 100%;
      max-height: max-content;
      margin: 2rem 5rem 0 2rem;
      // background-color: red;

      h1 {
        font-size: 32px;
      }
    }

    .pets_container {
      width: 100%;
      min-height: max-content;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      padding: 2.5rem 0 2.5rem 0rem;
      gap: 2.5rem;
      // background-color: black;
      border-top: 1px solid black;

      .pet_card {
        margin: 1rem;
        width: max-content;
        height: max-content;
        padding: 1.5rem 2.5rem;
        border-radius: 27px;
        background-color: #fff;
        box-shadow: 1px 1px 5px #aaaaaa, -1px -1px 5px #aaaaaa;

        &:hover {
          cursor: pointer;
        }

        .image_and_name {
          width: 100%;
          height: max-content;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .image_container {
            width: 125px;
            height: 125px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .pet_name {
            margin: 1rem 0;
            font-size: 20px;
            font-weight: 500;
          }
        }

        .more_info {
          width: 100%;
          height: max-content;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 0.5rem;
          // background-color: green;
          padding-right: 2.5rem;

          p {
            font-size: 16px;
            margin: 0;

            span {
              font-size: 16px;
              font-weight: 500;
              margin-right: 0.5rem;
            }
          }
        }
      }

      .selected {
        //   border: 2px solid #feca03;
        box-shadow: 1px 1px 5px #feca03, -1px -1px 5px #feca03;

        .image_and_name {
          .image_container {
            border: 2px solid #feca03;
          }

          .pet_name {
            color: #feca03;
          }
        }
      }
    }

    .next_page_button {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: center;
      // background-color: red;
      margin-bottom: 5rem;

      button {
        padding: 0.5rem 6rem;
        background-color: #feca03;
        border: none;
        font-size: 20px;
        border-radius: 27px;
        font-weight: 600;
      }
    }
  }
}
