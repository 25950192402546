.enquiry-form-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 56px;
 
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    h2 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 1.8rem;
      color: #333;
    }
    .enquiry-form {
      display: flex;
      flex-direction: column;
  
      .form-group {
        margin-bottom: 8px;
  
        label {
          display: block;
          margin-bottom: 5px;
          font-weight: bold;
          font-size: 1rem;
          color: #555;
        }
  
        input,
        select,
        textarea {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 1rem;
          color: #333;
          outline: none;
  
          &:focus {
            border-color: #5c9ded;
          }
        }
  
        textarea {
          resize: vertical;
          min-height: 120px;
        }
  
        input[type="file"] {
          padding: 5px;
        }
      }
  
      .submit-button {
        padding: 12px;
        background-color: #feca05;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1rem;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #000;
        }
  
        &:active {
          background-color: #387637;
        }
      }
    }
  }
  