* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.terms_condition{
  width: 100%;
  height: auto;
  display: flex;
  // border: 1px solid red;
  justify-content: center;
  .terms_and_policy{
      margin-top: 2rem;
      padding-bottom: 5rem;
      text-align: justify;
      width: 100%;
      padding: 0 5%;
      padding-bottom: 2.5rem;
      h5{
          font-family: "RecoletaSemiBold";

      }

      .point_of_terms {
          margin-left: 2.5rem;
      }
  }
}