* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .mybooking_host {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
    // background-color: orange;
  
    .mybooking-heading {
      width: 100%;
      height: auto;
  
      h4 {
        font-size: 28px;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding: 0.5rem;
      }
    }
  }
  
  .checkout_flex2_host {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    .view_order_button {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 1.5rem;
      margin-top: 0.5rem;
      // background-color: green;
  
      .pay_btn {
        width: 50%;
        background-color: #feca05;
        color: #000;
        text-align: center;
        padding: 0.3rem 0;
        border-radius: 27px;
        text-decoration: none;
        font-weight: 500;
      }
      .order_btn {
        width: 50%;
        background-color: #000;
        color: #fff;
        text-align: center;
        padding: 0.3rem 0;
        border-radius: 27px;
        text-decoration: none;
        font-weight: 500;
      }
      .paid {
        margin: 0;
      }
    }
    h5 {
      font-family: "RecoletaSemiBold";
    }
  
    .best-host-data {
      width: 100%;
      height: max-content;
      display: flex !important;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      // border: 1px solid black;
      gap: 1rem;
      padding-bottom: 5rem;
  
      .best-host-inner {
        width: 48%;
        height: 219px;
        display: flex;
        //   border: 1px solid red;
        background-color: white;
        justify-content: space-between;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
  
        .best_host_flex1 {
          width: 33%;
          height: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
  
          img {
            width: 90%;
            height: 90%;
            object-fit: cover;
            border-radius: 15px;
          }
        }
        .best_host_flex2 {
          width: 65%;
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          gap: 0px;
          margin-bottom: 33px;
          p {
            margin-top: 0;
            margin-bottom: 0rem;
            font-size:12px;
        }
          .role {
            color: black;
          }
  
          .timings {
            width: 100%;
            height: max-content;
            display: flex;
  
            .start,
            .end {
              width: 50%;
  
              p {
                font-size: 10px;
                margin: 0;
                padding: 0;
                color: #000;
  
                span {
                  color: gray;
                }
              }
            }
  
            // p {
            //   margin: 0;
            //   padding: 0;
            //   display: inline;
            //   margin-right: 0.5rem;
  
            //   span {
            //     color: gray;
            //   }
            // }
          }
  
          h6 {
            padding: 0;
            margin: 0;
  
            &:nth-child(3) {
              font-size: 14px;
              font-family: "RecoletaSemiBold";
              color: black;
            }
  
            // &:nth-child(4) {
            //     font-size: 14px;
            //     font-family: "RecoletaSemiBold";
            //     color: green;
  
            // }
          }
        }
      }
    }
  }
  